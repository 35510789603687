import axios from '../../../../axios.conf';
import { toast } from 'react-toastify';
import { ServerError } from '../../../../library/constants';
import secureLocalStorage from 'react-secure-storage';
import {
  FETCH_START,
  FETCH_END,
  SALES_INVOICE_QUERY_LIST,
  SALES_INVOICE_QUERY_FIELDS_SUCCESS,
  SALES_DATA_QUERY_LABELS_SUCCESS,
  SALES_INVOICE_QUERY_SINGLE_SUCCESS,
  SALES_INVOICE_UPLOAD_SUCCESS,
  ALL_SALES_DATA_LIST_SUCCESS,
  SALES_DATA_DETAILS_SINGLE_SUCCESS,
  SALES_DATA_UPLOAD_SUCCESS,
  PURCHASE_INVOICE_QUERY_LIST,
  PURCHASE_INVOICE_QUERY_FIELDS_SUCCESS,
  PURCHASE_INVOICE_QUERY_SINGLE_SUCCESS,
  PURCHASE_INVOICE_UPLOAD_SUCCESS,
  INCOMING_CLAIMS_QUERY_LIST,
  TARGET_INVOICE_LIST,
  COMMITMENT_DATA_SINGLE_SUCCESS,
  TARGET_INVOICE_QUERY_LIST,
  TARGET_INVOICE_QUERY_FIELDS_SUCCESS,
  TARGET_INVOICE_QUERY_SINGLE_SUCCESS,
  UPLOAD_ELIGIBILITY_RULES_SUCCESS,
  MATERIAL_MASTER_FIELDS_SUCCESS,
  MATERIAL_MASTER_SINGLE_FETCH_SUCCESS,
  MATERIAL_MASTER_UPLOAD_SUCCESS,
  CUSTOMER_MASTER_FIELDS_SUCCESS,
  MATERIAL_MASTER_QUERY_LIST,
  MATERIAL_UOM_QUERY_LIST,
  MATERIAL_MASTER_ORGA_DATA,
  CUSTOMER_MASTER_LIST,
  SUPPLIER_MASTER_ORGA_DATA,
  CUSTOMER_MASTER_SINGLE_FETCH_SUCCESS,
  UPLOAD_ELIGIBILITY_RULES_CLEAR,
  INDIRECT_SALES_INVOICE_QUERY_LIST,
  INDIRECT_SALES_INVOICE_QUERY_FIELDS_SUCCESS,
  INDIRECT_SALES_INVOICE_QUERY_SINGLE_SUCCESS,
  OUTGOING_CLAIMS_QUERY_LIST,
  CUSTOMER_MASTER_ORG_LIST,
  INCOMING_CLAIM_RESPONSE_FORMAT_FIELDS,
  INCOMING_CLAIM_RESPONSE_FORMAT_SUCCESS,
  OUTGOING_CLAIM_DOWNLOAD_FORMAT_SUCCESS,
  OUTGOING_CLAIM_DOWNLOAD_FORMAT_FIELDS,
  OUTGOING_CLAIM_GET_BY_ID,
  INVENTORY_DATA_SINGLE_SUCCESS,
  INVENTORY_DATA_SUCCESS,
  INVENTORY_DATA_FORMAT_BY_ID_SUCCESS,
  INVENTORY_DATA_FORMAT_FIELDS_SUCCESS,
  INVENTORY_FORMAT_SUCCESS,
  INVENTORY_DATA_UPLOAD_SUCCESS,
  //demand supply
  DEMAND_SUPPLY_SINGLE_SUCCESS,
  DEMAND_SUPPLY_SUCCESS,
  DEMAND_SUPPLY_FORMAT_BY_ID_SUCCESS,
  DEMAND_SUPPLY_FORMAT_FIELDS_SUCCESS,
  DEMAND_FORMAT_SUCCESS,
  DEMAND_SUPPLY_UPLOAD_SUCCESS,
  UPLOAD_FAILURE,
  OFF_INVOICE_COST_ERROR_LIST_SUCCESS,
  OFF_INVOICE_COST_ERROR_LIST_FAILED,
  OFFINVOICE_COST_FORMAT_SUCCESS,
  OFFINVOICE_COST_FORMAT_BY_ID_SUCCESS,
  OFFINVOICE_COST_FORMAT_FIELDS_SUCCESS,
  OFFINVOICE_COST_UPLOAD_SUCCESS,
  SALES_FORECAST_DATA_SUCCESS,
  SALES_FORECAST_SUCCESS,
  SALES_FORECAST_FORMAT_SUCCESS,
  SALES_FORECAST_FORMAT_BY_ID_SUCCESS,
  SALES_FORECAST_FORMAT_FIELDS_SUCCESS,
  COVERAGE_COST_DATA_SUCCESS,
  UPLOAD_COVERAGE_COST_SUCCESS,
  COVERAGE_COST_FORMAT_SUCCESS,
  COVERAGE_COST_FORMAT_BY_ID_SUCCESS,
  COVERAGE_COST_FORMAT_FIELDS_SUCCESS,
  COVERAGE_COST_SINGLE_SUCCESS,
  BATCH_TYPE_FREQ_SUCCESS,
  COMPETITOR_DATA,
  COMPETITOR_DATA_BY_ID,
  COMPETITOR_DATA_FORMAT_SUCCESS,
  COMPETITOR_DATA_FORMAT_BY_ID_SUCCESS,
  COMPETITOR_DATA_FORMAT_FIELDS_SUCCESS,
  COMPETITOR_DATA_UPLOAD_SUCCESS,
  MULTIPLE_CLAIMS_DETAIL_SUCCESS,
  TARGET_DATA_UPLOAD_SUCCESS,
  INCOMING_CLAIMS_SUCCESS,
  MASTER_DATA_DASHBOARD_SUCCESS,
  MASTER_DATA_DASHBOARD_TRANSACTION_SUCCESS,
  MASTER_DATA_DASHBOARD_CHANGES_SUCCESS,
  MASTER_OPERATION_DATA_SUCCESS,
  MASTER_DATA_CHART_SUCCESS,
  ALL_INDIRECT_SALES_DATA_LIST_SUCCESS,
  INDIRECT_SALES_DATA_DETAILS_SINGLE_SUCCESS,
  ALL_PURCHASE_SALES_DATA_LIST_SUCCESS,
  PURCHASE_SALES_DATA_DETAILS_SINGLE_SUCCESS,
  MASTER_DATA_CONFIG_SUCCESS,
  APPLICATION_LOGS_SUCCESS,
  BUILD_SALES_DATA_LABELS,
} from './ActionTypes';
import { history } from '../../../../components/Helpers';

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const salesDataUpload = (file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    salesDataUploadApi(file)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success('File have been successfully updated.');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const salesDataUploadApi = (file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result = axios.post('sales_data/upload', file, { headers: headers });
  return result;
};

//new sales data
export const salesDataUploadNew = (file, id, type, batchJobName) => {
  return (dispatch) => {
    dispatch(fetchStart());
    salesDataUploadNewAPI(file, id, type, batchJobName)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          if (type === 'BatchJob') {
            toast.success(response.data.response);
          }
          dispatch(uploadSalesDataSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //if (error.response && error.response.data && error.response.data.complete_error_message)
        //toast.error(error.response.data.complete_error_message)
      });
  };
};

const salesDataUploadNewAPI = (file, id, type, batchJobName) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  if (type === 'BatchJob') {
    result = axios.post(
      'batch-job?app_type=Sales%20Data&job_type=File Upload&job_name=' +
      batchJobName +
      '&format_id=' +
      id,
      file,
      { headers: headers },
    );
  } else if (type === 'upload') {
    result = axios.post('upload-file?format_id=' + id, file, {
      headers: headers,
    });
  } else {
    result = axios.post('sales_data_upload?format_id=' + id, file, {
      headers: headers,
    });
  }
  return result;
};

export const uploadSalesDataSuccess = (data) => {
  return {
    type: SALES_INVOICE_UPLOAD_SUCCESS,
    data,
  };
};
//fetch single sales query
export const fetchSalesInvoiceQuerySingle = (id, type) => {
  return (dispatch) => {
    fetchSalesInvoiceQuerySingleAPI(id, type)
      .then((response) => {
        dispatch(fetchSalesInvoiceQuerySingleSuccess(response.data.response));
        if (type === 'purchase') {
          history.push('/purchase-invoice/edit/' + id);
        } else if (type === 'indirect') {
          history.push('/indirect-sales-inovoice-query/edit/' + id);
        } else {
          history.push('/sales-inovoice-query/edit/' + id);
        }
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};

export const fetchSalesInvoiceQuerySingleSuccess = (data) => {
  return {
    type: SALES_INVOICE_QUERY_SINGLE_SUCCESS,
    data,
  };
};

const fetchSalesInvoiceQuerySingleAPI = (id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (type === 'purchase') {
    var result = axios.get(
      'upload-format/' + id + '?app-functionality=PurchaseDataUploadFormat',
      { headers: headers },
    );
    return result;
  } else if (type === 'indirect') {
    var result = axios.get(
      'upload-format/' + id + '?app-functionality=IndirectSalesDataFormat',
      { headers: headers },
    );
    return result;
  } else {
    var result = axios.get(
      'upload-format/' + id + '?app-functionality=SalesDataUploadFormat',
      { headers: headers },
    );
    return result;
  }
};

export const incomingClaimDataUpload = (file, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    incomingClaimDataUploadApi(file, id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.messageLog) {
          dispatch(incomingClaimsSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        if (error.response.status == '422')
          dispatch(incomingClaimsSuccess(error.response.data));
      });
  };
};

const incomingClaimDataUploadApi = (file, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result = axios.post('incoming-claim?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const incomingClaimsSuccess = (data) => {
  return {
    type: INCOMING_CLAIMS_SUCCESS,
    data,
  };
};
//outgoing claim data upload
export const outgoingClaimDataUpload = (file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    outgoingClaimDataUploadAPI(file)
      .then((response) => {
        dispatch(fetchEnd());
        toast('File have been successfully uploaded.');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const outgoingClaimDataUploadAPI = (file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result = axios.post('response_upload', file, { headers: headers });
  return result;
};

//load default fields
export const loadSalesDataFormatFields = () => {
  return (dispatch) => {
    loadSalesDataFormatFieldsAPI()
      .then((response) => {
        dispatch(loadSalesDataFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};
const loadSalesDataFormatFieldsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=SalesDataUploadFormat',
    { headers: headers },
  );
  return result;
};
export const loadSalesDataFormatFieldsSuccess = (data) => {
  return {
    type: SALES_INVOICE_QUERY_FIELDS_SUCCESS,
    data,
  };
};

// get labels sales data upload

export const loadSalesDataLabelsFormatFields = () => {
  return (dispatch) => {
    loadSalesDataLabelsFormatFieldsAPI()
      .then((response) => {
        dispatch(
          loadSalesDataLabelsFormatFieldsSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};
const loadSalesDataLabelsFormatFieldsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  var result;
  result = axios.get(
    'field-label-designer?app_type=Customer Rebate&data_source_type=Direct - Sales Data',
    { headers: headers },
  );
  return result;
};
export const loadSalesDataLabelsFormatFieldsSuccess = (data) => {
  return {
    type: SALES_DATA_QUERY_LABELS_SUCCESS,
    data,
  };
};

// getSalesInvoiceQueryList
export const getSalesInvoiceQueryList = (page) => {
  return (dispatch) => {
    getSalesInvoiceQueryListAPI(page)
      .then((response) => {
        dispatch(getSalesInvoiceQueryListSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};

const getSalesInvoiceQueryListAPI = (page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (page === 'DirectSalesExternalCalculationFormat') {
    result = axios.get('upload-format?app-functionality=' + page, {
      headers: headers,
    });
  } else if (page === 'DirectSalesFinancialPostingFormat') {
    result = axios.get('upload-format?app-functionality=' + page, {
      headers: headers,
    });
  } else {
    result = axios.get(
      'upload-format?app-functionality=SalesDataUploadFormat',
      {
        headers: headers,
      },
    );
  }
  return result;
};

export const getSalesInvoiceQueryListSuccess = (data) => {
  return {
    type: SALES_INVOICE_QUERY_LIST,
    data,
  };
};

// postSalesDataQuery
export const postSalesDataQuery = (data) => {
  return (dispatch) => {
    postSalesDataQueryAPI(data).then((response) => {
      history.push('/sales-inovoice-query');
    });
  };
};

const postSalesDataQueryAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'upload-format?app-functionality=SalesDataUploadFormat',
    data,
    { headers: headers },
  );
  return result;
};

// edit sales query
export const putSalesDataQuery = (data, id, type) => {
  return (dispatch) => {
    putSalesDataQueryAPI(data, id, type).then((response) => {
      history.push('/sales-inovoice-query');
      toast.success(response.data.response);
      dispatch(getSalesInvoiceQueryList());
    });
  };
};

const putSalesDataQueryAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'delete') {
    result = axios.delete('upload-format/' + id, data, { headers: headers });
  } else {
    result = axios.put(
      'upload-format/' + id + '?app-functionality=SalesDataUploadFormat',
      data,
      { headers: headers },
    );
  }

  return result;
};

export const getPurchaseInvoiceQueryList = () => {
  return (dispatch) => {
    getPurchaseInvoiceQueryListAPI()
      .then((response) => {
        dispatch(getPurchaseInvoiceQueryListAPISuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};

const getPurchaseInvoiceQueryListAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=PurchaseDataUploadFormat',
    { headers: headers },
  );
  return result;
};

export const getPurchaseInvoiceQueryListAPISuccess = (data) => {
  return {
    type: PURCHASE_INVOICE_QUERY_LIST,
    data,
  };
};
// post purchase data
export const postPurchaseDataQuery = (data, id, type) => {
  return (dispatch) => {
    postPurchaseDataQueryAPI(data, id, type).then((response) => {
      history.push('/purchase-invoice');
      toast.success(response.data.response);
      dispatch(getPurchaseInvoiceQueryList());
    });
  };
};

const postPurchaseDataQueryAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'put') {
    result = axios.put(
      'upload-format/' + id + '?app-functionality=PurchaseDataUploadFormat',
      data,
      { headers: headers },
    );
  } else if (type === 'delete') {
    result = axios.delete('upload-format/' + id, data, { headers: headers });
  } else {
    result = axios.post(
      'upload-format?app-functionality=PurchaseDataUploadFormat',
      data,
      { headers: headers },
    );
  }
  return result;
};

//load default fields purchase data
export const loadPurchaseDataFormatFields = (data) => {
  return (dispatch) => {
    loadPurchaseDataFormatFieldsAPI(data)
      .then((response) => {
        dispatch(
          loadPurchaseDataFormatFieldsAPISuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};

const loadPurchaseDataFormatFieldsAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=PurchaseDataUploadFormat',
    { params: data },
    { headers: headers },
  );
  return result;
};

export const loadPurchaseDataFormatFieldsAPISuccess = (data) => {
  return {
    type: PURCHASE_INVOICE_QUERY_FIELDS_SUCCESS,
    data,
  };
};

//fetch single purchase query

export const fetchPurchaseInvoiceQuerySingle = (id) => {
  return (dispatch) => {
    fetchPurchaseInvoiceQuerySingleAPI(id)
      .then((response) => {
        dispatch(fetchSalesInvoiceQuerySingleSuccess(response.data.response));
        history.push('/purchase-invoice/edit/' + id);
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};

export const fetchPurchaseInvoiceQuerySingleAPISuccess = (data) => {
  return {
    type: PURCHASE_INVOICE_QUERY_SINGLE_SUCCESS,
    data,
  };
};
const fetchPurchaseInvoiceQuerySingleAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('purchase-data-upload-format/' + id, {
    headers: headers,
  });
  return result;
};

//purchase sales data
export const purchaseDataUploadNew = (file, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    purchaseDataUploadNewAPI(file, id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadPurchaseDataSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //if (error.response && error.response.data && error.response.data.complete_error_message)
        //toast.error(error.response.data.complete_error_message)
      });
  };
};

const purchaseDataUploadNewAPI = (file, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result = axios.post('purchase-data-upload?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const uploadPurchaseDataSuccess = (data) => {
  return {
    type: PURCHASE_INVOICE_UPLOAD_SUCCESS,
    data,
  };
};

//upload eligbility rules
export const uploadEligibilityRules = (
  file,
  method,
  appType,
  SourceDataType,
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadEligibilityRulesAPI(file, method, appType, SourceDataType)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(uploadEligibilityRulesAPISuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

//clear upload eligbility rules
export const uploadEligibilityRulesClear = () => {
  return (dispatch) => {
    dispatch(uploadEligibilityRulesAPIClear([]));
  };
};

const uploadEligibilityRulesAPI = (file, method, appType, SourceDataType) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result = axios.post(
    'eligibility-rules/upload?calculation_method=' +
    method +
    '&application_type=' +
    appType +
    '&data_source_type=' +
    SourceDataType,
    file,
    { headers: headers },
  );
  return result;
};

export const uploadEligibilityRulesAPISuccess = (data) => {
  return {
    type: UPLOAD_ELIGIBILITY_RULES_SUCCESS,
    data,
  };
};

export const uploadEligibilityRulesAPIClear = (data) => {
  return {
    type: UPLOAD_ELIGIBILITY_RULES_CLEAR,
    data,
  };
};
//get incoming claims query list
export const getIncomingClaimsQueryList = () => {
  return (dispatch) => {
    getIncomingClaimsQueryListAPI()
      .then((response) => {
        dispatch(getIncomingClaimsQueryListSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const getIncomingClaimsQueryListAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=IndirectSalesDataFormat',
    { headers: headers },
  );
  return result;
};

export const getIncomingClaimsQueryListSuccess = (data) => {
  return {
    type: INCOMING_CLAIMS_QUERY_LIST,
    data,
  };
};

// post incoming claims
export const postIncomingClaimsQuery = (data, type) => {
  return (dispatch) => {
    postIncomingClaimsQueryAPI(data)
      .then((response) => {
        history.push('/supplier-incoming-claims-upload');
        toast.success(response.data.response);
      })
      .catch((error) => {
        //toast.error('Error')
      });
  };
};

const postIncomingClaimsQueryAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'upload-format?app-functionality=IndirectSalesDataFormat',
    data,
    { headers: headers },
  );
  return result;
};

export const putIncomingClaimsQuery = (data, id) => {
  return (dispatch) => {
    putIncomingClaimsQueryAPI(data, id)
      .then((response) => {
        history.push('/supplier-incoming-claims-upload');
        toast.success(response.data.response);
      })
      .catch((error) => {
        //toast.error('Error')
      });
  };
};

const putIncomingClaimsQueryAPI = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'upload-format/' + id + '?app-functionality=IndirectSalesDataFormat',
    data,
    { headers: headers },
  );
  return result;
};
//delete icoming claim
export const deleteIncomingClaimsQuery = (id) => {
  return (dispatch) => {
    deleteIncomingClaimsQueryAPI(id)
      .then((response) => {
        dispatch(getIncomingClaimsQueryList());
        history.push('/supplier-incoming-claims-upload');
        toast.success(response.data.response);
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message)
      });
  };
};
const deleteIncomingClaimsQueryAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};

//incoming claims new upload
export const incomingClaimsUploadNew = (file, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    incomingClaimsUploadNewAPI(file, id)
      .then((response) => {
        dispatch(fetchEnd());
        toast('File have been successfully updated.');
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const incomingClaimsUploadNewAPI = (file, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result = axios.post('sales_data_upload?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};

//create commitment data
export const postTargetData = (data) => {
  return (dispatch) => {
    postTargetDataAPI(data)
      .then((response) => {
        history.push('/target-invoice-query');
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};

const postTargetDataAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('target_data', data, { headers: headers });
  return result;
};
// get list commitment data
export const getTargetInvoiceList = (pagination, limit, keyword, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getTargetInvoiceListAPI(pagination, limit, keyword, data)
      .then((response) => {
        if (keyword === 'search') {
          dispatch(fetchEnd());
          dispatch(getTargetInvoiceListSuccess(response.data.response));
          history.push({ pathname: '/target-invoice-query', type: 'edit' });
          // history.push('/target-invoice-query')
        } else {
          dispatch(fetchEnd());
          dispatch(getTargetInvoiceListSuccess(response.data.response));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error('Error');
      });
  };
};

const getTargetInvoiceListAPI = (pagination, limit, keyword, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'target_data?page=' + pagination + '&limit=' + limit,
    { params: data },
    { headers: headers },
  );
  return result;
};

const getTargetInvoiceListSuccess = (data) => {
  return {
    type: TARGET_INVOICE_LIST,
    data,
  };
};

// edit, delete commitment data
export const putTargetData = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    putTargetDataAPI(data, id, type).then((response) => {
      dispatch(fetchEnd());
      toast.success(response.data.response);
      dispatch(
        getTargetInvoiceList(
          1,
          0,
          'search',
          JSON.parse(sessionStorage.getItem('search-data-commitment-data')),
        ),
      );
      // history.push("/target-invoice-query");
      history.push({ pathname: '/target-invoice-query', type: 'edit' });
    });
  };
};

const putTargetDataAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'delete') {
    result = axios.delete('target_data/' + id, data, { headers: headers });
  } else if (type === 'edit') {
    result = axios.put('target_data/' + id, data, { headers: headers });
  } else {
    result = axios.post('target_data', data, { headers: headers });
  }

  return result;
};

//get single commitment data
export const getCommitmentDataById = (id) => {
  return (dispatch) => {
    getCommitmentDataByIdAPI(id)
      .then((response) => {
        dispatch(getCommitmentDataByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};

export const getCommitmentDataByIdSuccess = (data) => {
  return {
    type: COMMITMENT_DATA_SINGLE_SUCCESS,
    data,
  };
};
const getCommitmentDataByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('target_data/' + id, { headers: headers });
  return result;
};

// target invoice query
export const getTargetInvoiceQueryList = () => {
  return (dispatch) => {
    getTargetInvoiceQueryListAPI()
      .then((response) => {
        dispatch(getTargetInvoiceQueryListSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};

const getTargetInvoiceQueryListAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format?app-functionality=TargetDataFormat', {
    headers: headers,
  });
  return result;
};

export const getTargetInvoiceQueryListSuccess = (data) => {
  return {
    type: TARGET_INVOICE_QUERY_LIST,
    data,
  };
};

//load default fields
export const loadTargetDataFormatFields = () => {
  return (dispatch) => {
    loadTargetDataFormatFieldsAPI()
      .then((response) => {
        dispatch(loadTargetDataFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};

const loadTargetDataFormatFieldsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=TargetDataFormat',
    { headers: headers },
  );
  return result;
};

export const loadTargetDataFormatFieldsSuccess = (data) => {
  return {
    type: TARGET_INVOICE_QUERY_FIELDS_SUCCESS,
    data,
  };
};

// posTargetDataQuery
export const postTargetDataQuery = (data) => {
  return (dispatch) => {
    postTargetDataQueryAPI(data).then((response) => {
      history.push('/target-invoice-query/excel-upload');
    });
  };
};

const postTargetDataQueryAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'upload-format?app-functionality=TargetDataFormat',
    data,
    { headers: headers },
  );
  return result;
};

//getch single target
export const fetchTargetInvoiceQuerySingle = (id) => {
  return (dispatch) => {
    fetchTargetInvoiceQuerySingleAPI(id)
      .then((response) => {
        dispatch(fetchTargetInvoiceQuerySingleSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};

export const fetchTargetInvoiceQuerySingleSuccess = (data) => {
  return {
    type: TARGET_INVOICE_QUERY_SINGLE_SUCCESS,
    data,
  };
};
const fetchTargetInvoiceQuerySingleAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'upload-format/' + id + '?app-functionality=TargetDataFormat',
    { headers: headers },
  );
  return result;
};
// edit target query
export const putTargetDataQuery = (data, id, type) => {
  return (dispatch) => {
    putTargetDataQueryAPI(data, id, type).then((response) => {
      history.push('/target-invoice-query/excel-upload');
      dispatch(getTargetInvoiceQueryList());
      toast.success(response.data.response);
    });
  };
};

const putTargetDataQueryAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'delete') {
    result = axios.delete('upload-format/' + id, data, { headers: headers });
  } else {
    result = axios.put(
      'upload-format/' + id + '?app-functionality=TargetDataFormat',
      data,
      { headers: headers },
    );
  }

  return result;
};

//new target data
export const targetDataUploadNew = (file, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    targetDataUploadNewAPI(file, id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(targetDataUploadSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const targetDataUploadNewAPI = (file, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result = axios.post('target-data-upload?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const targetDataUploadSuccess = (data) => {
  return {
    type: TARGET_DATA_UPLOAD_SUCCESS,
    data,
  };
};
//material master query list
export const getMaterialMasterQueryList = () => {
  return (dispatch) => {
    getMaterialMasterQueryListAPI()
      .then((response) => {
        dispatch(getMaterialMasterQueryListSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};

const getMaterialMasterQueryListAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=MaterialMasterDataFormat',
    { headers: headers },
  );
  return result;
};

export const getMaterialMasterQueryListSuccess = (data) => {
  return {
    type: MATERIAL_MASTER_QUERY_LIST,
    data,
  };
};
//material master uom list
export const getMaterialUoMQueryList = () => {
  return (dispatch) => {
    getMaterialUoMQueryListAPI()
      .then((response) => {
        dispatch(getMaterialUoMQueryListSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};
const getMaterialUoMQueryListAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format?app-functionality=MaterialUomLevelFormat', {
    headers: headers,
  });
  return result;
};

export const getMaterialUoMQueryListSuccess = (data) => {
  return {
    type: MATERIAL_UOM_QUERY_LIST,
    data,
  };
};
// material master orgainzation data
export const getMaterialMasterOrganization = () => {
  return (dispatch) => {
    getMaterialMasterOrganizationAPI()
      .then((response) => {
        dispatch(getMaterialMasterOrganizationSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};
const getMaterialMasterOrganizationAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=MaterialMasterOrganizationFormat',
    { headers: headers },
  );
  return result;
};

export const getMaterialMasterOrganizationSuccess = (data) => {
  return {
    type: MATERIAL_MASTER_ORGA_DATA,
    data,
  };
};
//material master list
export const loadMaterialMasterFormatFields = (format) => {
  return (dispatch) => {
    loadMaterialMasterFormatFieldsAPI(format)
      .then((response) => {
        dispatch(loadMaterialMasterFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};

const loadMaterialMasterFormatFieldsAPI = (format) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  var result;
  if (format === 'Material Master UoM Data') {
    result = axios.get(
      'upload-format-fields?app-functionality=MaterialUomLevelFormat',
      { headers: headers },
    );
  } else if (format === 'Material Master Organization Data') {
    result = axios.get(
      'upload-format-fields?app-functionality=MaterialMasterOrganizationFormat',
      { headers: headers },
    );
  } else {
    result = axios.get(
      'upload-format-fields?app-functionality=MaterialMasterDataFormat',
      { headers: headers },
    );
  }
  return result;
};

export const loadMaterialMasterFormatFieldsSuccess = (data) => {
  return {
    type: MATERIAL_MASTER_FIELDS_SUCCESS,
    data,
  };
};

// post material master data
export const postMaterialMasterQuery = (data, format, id, type) => {
  return (dispatch) => {
    postMaterialMasterQueryAPI(data, format, id, type).then((response) => {
      history.push('/material-master/material-master-excel-upload');
    });
  };
};

const postMaterialMasterQueryAPI = (data, format, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'put') {
    if (format == 'Material Master Data') {
      result = axios.put(
        'upload-format/' + id + '?app-functionality=MaterialMasterDataFormat',
        data,
        { headers: headers },
      );
    } else if (format == 'Material Master Organization Data') {
      result = axios.put(
        'upload-format/' +
        id +
        '?app-functionality=MaterialMasterOrganizationFormat',
        data,
        { headers: headers },
      );
    } else {
      result = axios.put(
        'upload-format/' + id + '?app-functionality=MaterialUomLevelFormat',
        data,
        { headers: headers },
      );
    }
  } else {
    if (format == 'Material Master Data') {
      result = axios.post(
        'upload-format?app-functionality=MaterialMasterDataFormat',
        data,
        { headers: headers },
      );
    } else if (format == 'Material Master Organization Data') {
      result = axios.post(
        'upload-format?app-functionality=MaterialMasterOrganizationFormat',
        data,
        { headers: headers },
      );
    } else {
      result = axios.post(
        'upload-format?app-functionality=MaterialUomLevelFormat',
        data,
        { headers: headers },
      );
    }
  }
  return result;
};
//get material master query
export const getMaterialMasterQuery = (id) => {
  return (dispatch) => {
    getMaterialMasterQueryAPI(id).then((response) => {
      dispatch(getMaterialMasterQueryAPISuccess(response.data.response));
      dispatch(getMaterialMasterQueryList());
    });
  };
};

const getMaterialMasterQueryAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format/' + id, { headers: headers });
  return result;
};

export const getMaterialMasterQueryAPISuccess = (data) => {
  return {
    type: MATERIAL_MASTER_SINGLE_FETCH_SUCCESS,
    data,
  };
};

//customer master list
export const loadCustomerMasterFormatFields = (page, type) => {
  return (dispatch) => {
    loadCustomerMasterFormatFieldsAPI(page, type)
      .then((response) => {
        dispatch(loadCustomerMasterFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};

const loadCustomerMasterFormatFieldsAPI = (page, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  var result;
  if (page === 'CustomerMasterXREF') {
    result = axios.get(
      'upload-format-fields?app-functionality=XrefCustomerDataFormats',
      { headers: headers },
    );
  } else if (page === 'MaterialXMaster') {
    result = axios.get(
      'upload-format-fields?app-functionality=XrefMaterialMasterDataFormats',
      { headers: headers },
    );
  } else if (page === 'Employee') {
    result = axios.get(
      'upload-format-fields?app-functionality=EmployeeFormats',
      { headers: headers },
    );
  } else if (page === 'DirectSalesExternalCalculationFormat') {
    result = axios.get(
      'upload-format-fields?app-functionality=DirectSalesExternalCalculationFormat',
      { headers: headers },
    );
  } else if (page === 'DirectSalesFinancialPostingFormat') {
    result = axios.get(
      'upload-format-fields?app-functionality=DirectSalesFinancialPostingFormat',
      { headers: headers },
    );
  } else if (page === 'SupplierMaster') {
    if (type === 'Supplier Master Organization Data') {
      result = axios.get(
        'upload-format-fields?app-functionality=SupplierMasterOrganizationsFormats',
        { headers: headers },
      );
    } else {
      result = axios.get(
        'upload-format-fields?app-functionality=SupplierDataFormats',
        { headers: headers },
      );
    }
  } else {
    if (type === 'Customer Sales Organization Data') {
      result = axios.get(
        'upload-format-fields?app-functionality=CustomerDataOrgUploadFormat',
        { headers: headers },
      );
    } else {
      result = axios.get(
        'upload-format-fields?app-functionality=CustomerDataUploadFormat',
        { headers: headers },
      );
    }
  }
  return result;
};

export const loadCustomerMasterFormatFieldsSuccess = (data) => {
  return {
    type: CUSTOMER_MASTER_FIELDS_SUCCESS,
    data,
  };
};
// post customer master data
export const postCustomerMasterQuery = (data, id, type, page, format) => {
  return (dispatch) => {
    postCustomerMasterQueryAPI(data, id, type, page, format).then(
      (response) => {
        toast.success(response.data.response);
        if (page === 'CustomerMasterXREF') {
          history.push('/customer-x-master/customer-x-master-excel-upload');
        } else if (page === 'DirectSalesExternalCalculationFormat') {
          history.push('/upload-external-calculation-main');
        } else if (page === 'DirectSalesFinancialPostingFormat') {
          history.push('/upload-external-posting-main');
        } else if (page === 'Employee') {
          history.push('/employee/employee-excel-upload');
        } else if (page === 'MaterialXMaster') {
          history.push('/material-x-master/material-x-master-excel-upload');
        } else if (page === 'SupplierMaster') {
          history.push('/supplier-master/supplier-master-excel-upload');
        } else {
          history.push('/customer-master/customer-master-excel-upload');
        }
      },
    );
  };
};

const postCustomerMasterQueryAPI = (data, id, type, page, format) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (page === 'CustomerMasterXREF') {
    if (type === 'put') {
      result = axios.put(
        'upload-format/' + id + '?app-functionality=XrefCustomerDataFormats',
        data,
        { headers: headers },
      );
    } else {
      result = axios.post(
        'upload-format?app-functionality=XrefCustomerDataFormats',
        data,
        { headers: headers },
      );
    }
  } else if (page === 'DirectSalesExternalCalculationFormat') {
    if (type === 'put') {
      result = axios.put(
        'upload-format/' +
        id +
        '?app-functionality=DirectSalesExternalCalculationFormat',
        data,
        { headers: headers },
      );
    } else {
      result = axios.post(
        'upload-format?app-functionality=DirectSalesExternalCalculationFormat',
        data,
        { headers: headers },
      );
    }
  } else if (page === 'DirectSalesFinancialPostingFormat') {
    if (type === 'put') {
      result = axios.put(
        'upload-format/' +
        id +
        '?app-functionality=DirectSalesFinancialPostingFormat',
        data,
        { headers: headers },
      );
    } else {
      result = axios.post(
        'upload-format?app-functionality=DirectSalesFinancialPostingFormat',
        data,
        { headers: headers },
      );
    }
  } else if (page === 'MaterialXMaster') {
    if (type === 'put') {
      result = axios.put(
        'upload-format/' +
        id +
        '?app-functionality=XrefMaterialMasterDataFormats',
        data,
        { headers: headers },
      );
    } else {
      result = axios.post(
        'upload-format?app-functionality=XrefMaterialMasterDataFormats',
        data,
        { headers: headers },
      );
    }
  } else if (page === 'SupplierMaster') {
    if (type === 'put') {
      if (format === 'Supplier Master Organization Data') {
        result = axios.put(
          'upload-format/' +
          id +
          '?app-functionality=SupplierMasterOrganizationsFormats',
          data,
          { headers: headers },
        );
      } else {
        result = axios.put(
          'upload-format/' + id + '?app-functionality=SupplierDataFormats',
          data,
          { headers: headers },
        );
      }
    } else {
      if (format === 'Supplier Master Organization Data') {
        result = axios.post(
          'upload-format?app-functionality=SupplierMasterOrganizationsFormats',
          data,
          { headers: headers },
        );
      } else {
        result = axios.post(
          'upload-format?app-functionality=SupplierDataFormats',
          data,
          { headers: headers },
        );
      }
    }
  } else if (page === 'Employee') {
    if (type === 'put') {
      result = axios.put(
        'upload-format/' + id + '?app-functionality=EmployeeFormats',
        data,
        { headers: headers },
      );
    } else {
      result = axios.post(
        'upload-format?app-functionality=EmployeeFormats',
        data,
        { headers: headers },
      );
    }
  } else {
    if (type === 'put') {
      if (format === 'Customer Sales Organization Data') {
        result = axios.put(
          'upload-format/' +
          id +
          '?app-functionality=CustomerDataOrgUploadFormat',
          data,
          { headers: headers },
        );
      } else
        result = axios.put(
          'upload-format/' + id + '?app-functionality=CustomerDataUploadFormat',
          data,
          { headers: headers },
        );
    } else if (format === 'Customer Sales Organization Data') {
      result = axios.post(
        'upload-format?app-functionality=CustomerDataOrgUploadFormat',
        data,
        { headers: headers },
      );
    } else {
      result = axios.post(
        'upload-format?app-functionality=CustomerDataUploadFormat',
        data,
        { headers: headers },
      );
    }
  }

  return result;
};

//get customer material master query list
export const getCustomerMasterQueryList = (page, type) => {
  return (dispatch) => {
    getCustomerMasterQueryListAPI(page, type)
      .then((response) => {
        dispatch(getCustomerMasterQueryListSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};

const getCustomerMasterQueryListAPI = (page, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (page === 'CustomerMasterXREF') {
    result = axios.get(
      'upload-format?app-functionality=XrefCustomerDataFormats',
      { headers: headers },
    );
  } else if (page === 'MaterialXMaster') {
    result = axios.get(
      'upload-format?app-functionality=XrefMaterialMasterDataFormats',
      { headers: headers },
    );
  } else if (page === 'Employee') {
    result = axios.get('upload-format?app-functionality=EmployeeFormats', {
      headers: headers,
    });
  } else if (page === 'SupplierMaster') {
    result = axios.get('upload-format?app-functionality=SupplierDataFormats', {
      headers: headers,
    });
  } else if (page === 'Bom')
    result = axios.get('upload-format?app-functionality=BomFormats', {
      headers: headers,
    });
  else if (page === 'XrefClassOfTradeFormat')
    result = axios.get(
      'upload-format?app-functionality=XrefClassOfTradeFormat',
      {
        headers: headers,
      },
    );
  else {
    if (type === 'Customer Sales Organization Data') {
      result = axios.get(
        'upload-format?app-functionality=CustomerDataOrgUploadFormat',
        { headers: headers },
      );
    } else {
      result = axios.get(
        'upload-format?app-functionality=CustomerDataUploadFormat',
        { headers: headers },
      );
    }
  }
  return result;
};

export const getCustomerMasterQueryListSuccess = (data) => {
  return {
    type: CUSTOMER_MASTER_LIST,
    data,
  };
};
export const getCustomerMasterOrgQueryList = (page) => {
  return (dispatch) => {
    getCustomerMasterOrgQueryListAPI(page)
      .then((response) => {
        dispatch(getCustomerMasterOrgQueryListSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};

const getCustomerMasterOrgQueryListAPI = (page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=CustomerDataOrgUploadFormat',
    { headers: headers },
  );
  return result;
};

export const getCustomerMasterOrgQueryListSuccess = (data) => {
  return {
    type: CUSTOMER_MASTER_ORG_LIST,
    data,
  };
};

//get customer master query
export const getCustomerMasterQuery = (id, type) => {
  return (dispatch) => {
    getCustomerMasterQueryAPI(id, type).then((response) => {
      dispatch(getCustomerMasterQueryAPISuccess(response.data.response));
      // dispatch(getMaterialMasterQueryList());
    });
  };
};

const getCustomerMasterQueryAPI = (id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'Customer Sales Organization Data') {
    result = axios.get(
      '/upload-format/' + id + '?app-functionality=CustomerDataOrgUploadFormat',
      { headers: headers },
    );
  } else if (type === 'Supplier Master Data') {
    result = axios.get(
      'upload-format/' + id + '?app-functionality=SupplierDataFormats',
      { headers: headers },
    );
  } else if (type === 'Employee') {
    result = axios.get(
      'upload-format/' + id + '?app-functionality=EmployeeFormats',
      { headers: headers },
    );
  } else {
    result = axios.get(
      '/upload-format/' + id + '?app-functionality=CustomerDataUploadFormat',
      { headers: headers },
    );
  }

  return result;
};

export const getCustomerMasterQueryAPISuccess = (data) => {
  return {
    type: CUSTOMER_MASTER_SINGLE_FETCH_SUCCESS,
    data,
  };
};

// supplier master organization upload
export const getSupplierMasterOrganization = () => {
  return (dispatch) => {
    getSupplierMasterOrganizationAPI()
      .then((response) => {
        dispatch(getSupplierMasterOrganizationSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};
const getSupplierMasterOrganizationAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=SupplierMasterOrganizationsFormats',
    { headers: headers },
  );
  return result;
};

export const getSupplierMasterOrganizationSuccess = (data) => {
  return {
    type: SUPPLIER_MASTER_ORGA_DATA,
    data,
  };
};
//material master upload
export const materialMasterUpload = (file, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    materialMasterUploadAPI(file, id, type)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadMaterialMasterSuccess(response.data.response));
          if (
            !response.data.response.error_list &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          dispatch(fetchEnd());
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const materialMasterUploadAPI = (file, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  if (type === 'Material Master UoM Data')
    result = axios.post('material-uom-upload?format_id=' + id, file, {
      headers: headers,
    });
  else if (type === 'Material Master Organization Data')
    result = axios.post('material-master-org-upload?format_id=' + id, file, {
      headers: headers,
    });
  else
    result = axios.post('material-master-data-upload?format_id=' + id, file, {
      headers: headers,
    });

  return result;
};
export const uploadMaterialMasterSuccess = (data) => {
  return {
    type: MATERIAL_MASTER_UPLOAD_SUCCESS,
    data,
  };
};
//customer master upload
export const customerMasterUpload = (file, id, page, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    customerMasterUploadAPI(file, id, page, type)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(customerMasterUploadSuccess(response.data.response));
        if (
          !response.data.response.error_list &&
          response.data.response.error_list == null
        ) {
          toast.success(response.data.response.success_msg);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const customerMasterUploadAPI = (file, id, page, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  if (page === 'CustomerMasterXREF') {
    var result = axios.post('xref-customer-data-upload?format_id=' + id, file, {
      headers: headers,
    });
  } else if (page === 'SupplierMaster') {
    if (type === 'Supplier Master Organization Data') {
      var result = axios.post(
        'supplier-master-org-upload?format_id=' + id,
        file,
        { headers: headers },
      );
    } else {
      var result = axios.post(
        'supplier-master-data-upload?format_id=' + id,
        file,
        { headers: headers },
      );
    }
  } else if (page === 'MaterialXMaster') {
    var result = axios.post(
      'xref-material-master-data-upload?format_id=' + id,
      file,
      { headers: headers },
    );
  } else if (page === 'Employee') {
    var result = axios.post('employee-data-upload?format_id=' + id, file, {
      headers: headers,
    });
  } else {
    if (type === 'Customer Sales Organization Data') {
      var result = axios.post(
        'customer-data-org-upload?format_id=' + id,
        file,
        { headers: headers },
      );
    } else {
      var result = axios.post('/customer-data-upload?format_id=' + id, file, {
        headers: headers,
      });
    }
  }
  return result;
};
export const customerMasterUploadSuccess = (data) => {
  return {
    type: UPLOAD_FAILURE,
    data,
  };
};
//delete master queries common function
export const deleteMasterQuery = (id, type, format) => {
  return (dispatch) => {
    deleteMasterQueryAPI(id, type, format).then((response) => {
      toast.success(response.data.response);
      if (type === 'Customer Master') dispatch(getCustomerMasterQueryList());
      dispatch(getCustomerMasterOrgQueryList());
      if (type === 'Material Master') {
        dispatch(getMaterialMasterQueryList());
        dispatch(getMaterialUoMQueryList());
        dispatch(getMaterialMasterOrganization());
      }
      if (type === 'DirectSalesExternalCalculationFormat')
        dispatch(
          getSalesInvoiceQueryList('DirectSalesExternalCalculationFormat'),
        );
      if (type === 'DirectSalesFinancialPostingFormat')
        dispatch(getSalesInvoiceQueryList('DirectSalesFinancialPostingFormat'));
      if (type === 'CustomerMasterXREF')
        dispatch(getCustomerMasterQueryList('CustomerMasterXREF'));
      if (type === 'MaterialXMaster')
        dispatch(getCustomerMasterQueryList('MaterialXMaster'));
      if (type === 'SupplierMaster')
        dispatch(getCustomerMasterQueryList('SupplierMaster'));
      if (type === 'XrefClassOfTradeFormat')
        dispatch(getCustomerMasterQueryList('XrefClassOfTradeFormat'));
      if (type === 'Employee') dispatch(getCustomerMasterQueryList('Employee'));
      if (type === 'Bom') dispatch(getCustomerMasterQueryList('Bom'));
    });
  };
};

const deleteMasterQueryAPI = (id, type, format) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'Customer Master')
    if (format === 'Customer Sales Organization Data') {
      result = axios.delete(
        'upload-format/' +
        id +
        '?app-functionality=CustomerDataOrgUploadFormat',
        { headers: headers },
      );
    } else {
      result = axios.delete(
        'upload-format/' + id + '?app-functionality=CustomerDataUploadFormat',
        { headers: headers },
      );
    }

  if (type === 'Material Master') {
    result = axios.delete('upload-format/' + id, { headers: headers });
  }

  if (type === 'CustomerMasterXREF')
    result = axios.delete('upload-format/' + id, { headers: headers });
  if (type === 'DirectSalesExternalCalculationFormat')
    result = axios.delete('upload-format/' + id, { headers: headers });
  if (type === 'DirectSalesFinancialPostingFormat')
    result = axios.delete('upload-format/' + id, { headers: headers });
  if (type === 'MaterialXMaster')
    result = axios.delete('upload-format/' + id, { headers: headers });
  if (type === 'SupplierMaster')
    result = axios.delete('upload-format/' + id, { headers: headers });
  if (type === 'Employee')
    result = axios.delete('upload-format/' + id, { headers: headers });
  if (type === 'Bom')
    result = axios.delete('upload-format/' + id, { headers: headers });
  if (type === 'XrefClassOfTradeFormat')
    result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};
export const cloneMasterQuery = (id, type) => {
  const pagination = 1;
  const limit = 10;
  return (dispatch) => {
    getCloneMasterQueryApi(id)
      .then((response) => {
        toast.success('Format cloned successfully.');
        if (type === 'Material Master') {
          dispatch(getMaterialMasterQueryList());
          dispatch(getMaterialUoMQueryList());
          dispatch(getMaterialMasterOrganization());
        } else if (type == 'Supplier Master') {
          dispatch(getCustomerMasterQueryList('SupplierMaster'));
        } else if (type == 'MaterialXMaster') {
          dispatch(getCustomerMasterQueryList('MaterialXMaster'));
        } else if (type == 'CustomerMasterXREF') {
          dispatch(getCustomerMasterQueryList('CustomerMasterXREF'));
        } else if (type == 'Demand Supply') {
          dispatch(getDemandSupplyFormat());
        } else if (type == 'XrefClassOfTradeFormat') {
          dispatch(getCustomerMasterQueryList('XrefClassOfTradeFormat'));
        } else if (type == 'Employee Master') {
          dispatch(getCustomerMasterQueryList('Employee'));
        } else {
          dispatch(getCustomerMasterQueryList());
        }
      })
      .catch((error) => {
        //toast.error('Something went wrong.');;
      });
  };
};

const getCloneMasterQueryApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('clone-format/' + id + '?format_name=NothingCloned', {
    headers: headers,
  });
  return result;
};

//indirect sales invoice
// getSalesInvoiceQueryList
export const getIndirectSalesInvoiceQueryList = () => {
  return (dispatch) => {
    getIndirectSalesInvoiceQueryListAPI()
      .then((response) => {
        dispatch(
          getIndirectSalesInvoiceQueryListSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};
const getIndirectSalesInvoiceQueryListAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=IndirectSalesDataFormat',
    { headers: headers },
  );
  return result;
};

export const getIndirectSalesInvoiceQueryListSuccess = (data) => {
  return {
    type: INDIRECT_SALES_INVOICE_QUERY_LIST,
    data,
  };
};
export const postIndirectSalesDataQuery = (data, id, type) => {
  return (dispatch) => {
    postIndirectSalesDataQueryAPI(data, id, type).then((response) => {
      history.push('/indirect-sales-inovoice-query');
      toast.success(response.data.response);
      dispatch(getIndirectSalesInvoiceQueryList());
    });
  };
};

const postIndirectSalesDataQueryAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id) {
    if (type === 'delete')
      result = axios.delete('upload-format/' + id, data, { headers: headers });
    else
      result = axios.put(
        'upload-format/' + id + '?app-functionality=IndirectSalesDataFormat',
        data,
        { headers: headers },
      );
  } else {
    result = axios.post(
      'upload-format?app-functionality=IndirectSalesDataFormat',
      data,
      { headers: headers },
    );
  }
  return result;
};

//load default fields
export const loadIndirectSalesDataFormatFields = (data) => {
  return (dispatch) => {
    loadIndirectSalesDataFormatFieldsAPI(data)
      .then((response) => {
        dispatch(
          loadIndirectSalesDataFormatFieldsSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};

const loadIndirectSalesDataFormatFieldsAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  var result;
  result = axios.get(
    'upload-format-fields',
    { params: data },
    { headers: headers },
  );
  return result;
};

export const loadIndirectSalesDataFormatFieldsSuccess = (data) => {
  return {
    type: INDIRECT_SALES_INVOICE_QUERY_FIELDS_SUCCESS,
    data,
  };
};

//fetch single direc sales
export const fetchIndirectSalesInvoiceQuerySingle = (id) => {
  return (dispatch) => {
    fetchIndirectSalesInvoiceQuerySingleAPI(id)
      .then((response) => {
        dispatch(
          fetchIndirectSalesInvoiceQuerySingleSuccess(response.data.response),
        );
        history.push('/indirect-sales-inovoice-query/edit/' + id);
      })
      .catch((error) => {
        history.push('/indirect-sales-inovoice-query/edit/' + id);
        //toast.error(ServerError);
      });
  };
};
export const fetchIndirectSalesInvoiceQuerySingleSuccess = (data) => {
  return {
    type: INDIRECT_SALES_INVOICE_QUERY_SINGLE_SUCCESS,
    data,
  };
};
const fetchIndirectSalesInvoiceQuerySingleAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('indirect-sales-data-format-id?format_id=' + id, {
    headers: headers,
  });
  return result;
};
export const getAllSalesDataList = (pagination, data, limit) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAllSalesDataListAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.message);
        dispatch(getAllSalesDataListSuccess(response.data.response, null));
        if (sessionStorage.getItem('Syncfusion')) {
          history.push('/display-sales-invoice-data-sf');
        } else {
          history.push('/display-sales-invoice-data');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getAllSalesDataListAPI = (data) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const config = {
    headers: {
      Authorization: secureLocalStorage.getItem('aTk'),
    },
    params: data,
  };
  // const headers = {
  //   Authorization: secureLocalStorage.getItem("aTk"),
  // };
  const result = axios.get(
    'sales_data?select=ID,net_value,quantity,transaction_type,billing_date,billing_doc_number,billing_doc_line,customer_number,customer_name,material_number,material_description,net_value,quantity&page=1&limit=0',
    config,
  );

  return result;
};
export const getAllSalesDataListSuccess = (data, errorList) => {
  return {
    type: ALL_SALES_DATA_LIST_SUCCESS,
    data,
    errorList,
  };
};
//get All details of a particular sales data invoice by ID
export const getSalesDataDetailsById = (id) => {
  return (dispatch) => {
    getSalesDataDetailsByIdAPI(id)
      .then((response) => {
        dispatch(getSalesDataDetailsByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};
export const getSalesDataDetailsByIdSuccess = (data) => {
  return {
    type: SALES_DATA_DETAILS_SINGLE_SUCCESS,
    data,
  };
};
const getSalesDataDetailsByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('sales_data/' + id, { headers: headers });
  return result;
};
//new sales data
export const indirectSalesDataUploadNew = (file, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    indirectSalesDataUploadNewAPI(file, id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadIndirectSalesDataSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //if (error.response && error.response.data && error.response.data.complete_error_message)
        //toast.error(error.response.data.complete_error_message)
      });
  };
};

const indirectSalesDataUploadNewAPI = (file, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result = axios.post('indirect-sales-data-upload?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const uploadIndirectSalesDataSuccess = (data) => {
  return {
    type: SALES_DATA_UPLOAD_SUCCESS,
    data,
  };
};
//outgoing claims new
export const getOutgoingClaimsQueryList = () => {
  return (dispatch) => {
    getOutgoingClaimsQueryListAPI()
      .then((response) => {
        dispatch(getOutgoingClaimsQueryListSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};
const getOutgoingClaimsQueryListAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('claim-response-format-all', { headers: headers });
  return result;
};

export const getOutgoingClaimsQueryListSuccess = (data) => {
  return {
    type: OUTGOING_CLAIMS_QUERY_LIST,
    data,
  };
};
export const postOutgoingClaimsQuery = (data, type) => {
  return (dispatch) => {
    postOutgoingClaimsQueryAPI(data).then((response) => {
      history.push('/customer-outgoing-claims-upload-new');
    });
  };
};

const postOutgoingClaimsQueryAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('claim-response-format', data, { headers: headers });
  return result;
};
export const putOutgoingClaimsQuery = (data, id) => {
  return (dispatch) => {
    putOutgoingClaimsQueryAPI(data, id).then((response) => {
      history.push('/customer-outgoing-claims-upload-new');
    });
  };
};
const putOutgoingClaimsQueryAPI = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('claim-response-format/' + id, data, { headers: headers });
  return result;
};
export const outgoingClaimDataUploadNew = (file, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    outgoingClaimDataUploadNewAPI(file, id)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success('File have been successfully updated.');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
const outgoingClaimDataUploadNewAPI = (file, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result = axios.post('response_upload?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const runBuildSalesData = (data, type, item, UUID) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getBuildSalesDataAPi(data, type, item, UUID)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //dispatch(calculationSimulationFailed(error));
        //toast.error(error.response.data.complete_error_message)
      });
  };
};
const getBuildSalesDataAPi = (data, type, item, UUID) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'delete') {
    if (item == 'Sales data') {
      result = axios.delete(
        'sales-data?',
        { params: data },
        { headers: headers },
      );
    } else {
      result = axios.delete(
        'staging-sales-data',
        { params: data },
        { headers: headers },
      );
    }
  } else {
    if (UUID) {
      result = axios.post('map-sales-data-stage?job_id=' + UUID, data, { headers: headers });
    }
    else {
      result = axios.post('map-sales-data-stage', data, { headers: headers });
    }

  }

  return result;
};
export const runBuildIndirectSalesData = (data, type, item) => {
  return (dispatch) => {
    dispatch(fetchStart());
    runBuildIndirectSalesDataAPi(data, type, item)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //dispatch(calculationSimulationFailed(error));
        //toast.error(error.response.data.complete_error_message)
      });
  };
};
const runBuildIndirectSalesDataAPi = (data, type, item) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'delete') {
    if (item == 'Sales data') {
      result = axios.delete(
        'indirect-sales-data?',
        { params: data },
        { headers: headers },
      );
    } else {
      result = axios.delete(
        'indirect-staging-sales-data',
        { params: data },
        { headers: headers },
      );
    }
  } else {
    result = axios.post('map-indirect-sales-data-stage', data, {
      headers: headers,
    });
  }

  return result;
};
export const getBuildSalesDataFieldValue = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getBuildSalesDataFieldValuedAPI(data).then((response) => {
      dispatch(fetchEnd());
      dispatch(getBuildSalesDataFieldValueSuccess(response.data.response));
    });
  };
};
const getBuildSalesDataFieldValuedAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  let result = axios.get(
    'upload-format-fields',
    { params: data },
    { headers: headers },
  );
  return result;
};
export const getBuildSalesDataFieldValueSuccess = (data) => {
  return {
    type: BUILD_SALES_DATA_LABELS,
    data,
  };
};
export const runBuildPurchaseData = (data, type, item) => {
  return (dispatch) => {
    dispatch(fetchStart());
    runBuildPurchaseDataAPi(data, type, item)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //dispatch(calculationSimulationFailed(error));
        //toast.error(error.response.data.complete_error_message)
      });
  };
};
const runBuildPurchaseDataAPi = (data, type, item) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'delete') {
    if (item == 'Purchase data') {
      result = axios.delete(
        'purchase-data?',
        { params: data },
        { headers: headers },
      );
    } else {
      result = axios.delete(
        'staging-purchase-data',
        { params: data },
        { headers: headers },
      );
    }
  } else {
    result = axios.post('map-purchase-data-stage', data, { headers: headers });
  }

  return result;
};

//upload Compare Rebate

export const legacyCalculationsData = (data) => {
  return (dispatch) => {
    legacyCalculationsDataApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const legacyCalculationsDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('legacy_calculations_data', data, { headers: headers });
  return result;
};

//upload employee territory
export const uploadEmployeeTerritoryFile = (file) => {
  return (dispatch) => {
    uploadEmployeeTerritoryFileApi(file)
      .then((response) => {
        if (response.status === 200) {
          //window.location.reload();
          toast.success('File has been successfully uploaded.');
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadEmployeeTerritoryFileApi = (file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('employee-territory', file, { headers: headers });
  return result;
};
//incoming claim format
export const getIncomingClaimResponseFormat = () => {
  return (dispatch) => {
    getIncomingClaimResponseFormatAPI().then((response) => {
      dispatch(getIncomingClaimResponseFormatSuccess(response.data.response));
    });
  };
};
const getIncomingClaimResponseFormatAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format?app-functionality=ClaimResponseFormat', {
    headers: headers,
  });
  return result;
};

export const getIncomingClaimResponseFormatSuccess = (data) => {
  return {
    type: INCOMING_CLAIM_RESPONSE_FORMAT_SUCCESS,
    data,
  };
};

export const getIncomingClaimResponseFormatFields = () => {
  return (dispatch) => {
    getIncomingClaimResponseFormatFieldsAPI().then((response) => {
      dispatch(
        getIncomingClaimResponseFormatFieldsSuccess(response.data.response),
      );
    });
  };
};

const getIncomingClaimResponseFormatFieldsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=ClaimResponseFormat',
    { headers: headers },
  );
  return result;
};

export const getIncomingClaimResponseFormatFieldsSuccess = (data) => {
  return {
    type: INCOMING_CLAIM_RESPONSE_FORMAT_FIELDS,
    data,
  };
};

export const incomingClaimResponseFormat = (data, id, type) => {
  return (dispatch) => {
    incomingClaimResponseFormatAPI(data, id, type)
      .then((response) => {
        if (type == 'delete') {
          dispatch(getIncomingClaimResponseFormat());
        }

        toast.success(response.data.response);
        history.push('/supplier-incoming-claim-format');
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const incomingClaimResponseFormatAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'edit')
    result = axios.put(
      'upload-format/' + id + '?app-functionality=ClaimResponseFormat',
      data,
      { headers: headers },
    );
  else if (type == 'delete')
    result = axios.delete('upload-format/' + id, { headers: headers });
  else
    result = axios.post(
      'upload-format?app-functionality=ClaimResponseFormat',
      data,
      { headers: headers },
    );
  return result;
};
// multiple cliams
export const runMultipleClaims = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    runMultipleClaimsApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(multipleClaimsSuccess(response.data.response));
        history.push('/supplier-multiple-claims-result');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error('Something went wrong')
      });
  };
};

const runMultipleClaimsApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'indirect-sales-data?page=1&&limit=0',
    { params: data },
    { headers: headers },
  );

  return result;
};

export const multipleClaimsSuccess = (data) => {
  return {
    type: MULTIPLE_CLAIMS_DETAIL_SUCCESS,
    data,
  };
};
//outgong claim download format
export const getOutgoingClaimDownloadFormat = (functionality) => {
  return (dispatch) => {
    getOutgoingClaimDownloadFormatAPI(functionality).then((response) => {
      dispatch(getOutgoingClaimDownloadFormatSuccess(response.data.response));
    });
  };
};

const getOutgoingClaimDownloadFormatAPI = (functionality) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'outgoing-claim-download-format-all?functionality=' + functionality,
    { headers: headers },
  );
  return result;
};

export const getOutgoingClaimDownloadFormatSuccess = (data) => {
  return {
    type: OUTGOING_CLAIM_DOWNLOAD_FORMAT_SUCCESS,
    data,
  };
};

export const getOutgoingClaimDownloadFormatFields = () => {
  return (dispatch) => {
    getOutgoingClaimDownloadFormatFieldsAPI().then((response) => {
      dispatch(
        getOutgoingClaimDownloadFormatFieldsSuccess(response.data.response),
      );
    });
  };
};

const getOutgoingClaimDownloadFormatFieldsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('outgoing-claim-download-fields', { headers: headers });
  return result;
};

export const getOutgoingClaimDownloadFormatFieldsSuccess = (data) => {
  return {
    type: OUTGOING_CLAIM_DOWNLOAD_FORMAT_FIELDS,
    data,
  };
};
export const getOutGoingClaimById = (id) => {
  return (dispatch) => {
    getOutGoingClaimByIdAPI(id).then((response) => {
      dispatch(getOutGoingClaimByIdSuccess(response.data.response));
    });
  };
};

const getOutGoingClaimByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  let result;
  result = axios.get('outgoing-claim-download-format?format_id=' + id, {
    headers: headers,
  });
  return result;
};

export const getOutGoingClaimByIdSuccess = (data) => {
  return {
    type: OUTGOING_CLAIM_GET_BY_ID,
    data,
  };
};
export const outgoingClaimDownloadFormat = (functionality, data, id, type) => {
  return (dispatch) => {
    outgoingClaimDownloadFormatAPI(functionality, data, id, type)
      .then((response) => {
        if (type == 'delete') {
          dispatch(getOutgoingClaimDownloadFormat('download'));
        }

        toast.success(response.data.response);
        if (functionality == 'download')
          history.push('/customer-outgoing-claim-download-format');
        else history.push('/customer-outgoing-claims-upload-new');
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const outgoingClaimDownloadFormatAPI = (functionality, data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'edit')
    result = axios.put('outgoing-claim-download-format/' + id, data, {
      headers: headers,
    });
  else if (type == 'delete')
    result = axios.delete('outgoing-claim-download-format/' + id, {
      headers: headers,
    });
  else
    result = axios.post(
      'outgoing-claim-download-format?functionality=' + functionality,
      data,
      { headers: headers },
    );
  return result;
};
//outgoing claim finacial posting
export const outgoingClaimFinacialPosting = (claimNumber, data) => {
  return (dispatch) => {
    outgoingClaimFinacialPostingAPI(claimNumber, data).then((response) => {
      toast.success(response.data.response);
    });
  };
};

const outgoingClaimFinacialPostingAPI = (claimNumber, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (claimNumber) {
    result = axios.post('process-outgoing-claim?claim_number=' + claimNumber, {
      headers: headers,
    });
  } else {
    result = axios.post('process-outgoing-claim', data, { headers: headers });
  }
  return result;
};
// inventory data upload
export const getInventoryDataFormat = () => {
  return (dispatch) => {
    getInventoryDataFormatDataApi()
      .then((response) => {
        dispatch(getInventoryDataFormatSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getInventoryDataFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format?app-functionality=ChannelInventoryFormat', {
    headers: headers,
  });
  return result;
};

export const getInventoryDataFormatSuccess = (data) => {
  return {
    type: INVENTORY_FORMAT_SUCCESS,
    data,
  };
};

export const getInventoryDataFormatDataById = (id) => {
  return (dispatch) => {
    getInventoryDataFormatByIdApi(id)
      .then((response) => {
        dispatch(getInventoryDataFormatByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getInventoryDataFormatByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format/' + id + '?app-functionality=ChannelInventoryFormat',
    { headers: headers },
  );
  return result;
};

export const getInventoryDataFormatByIdSuccess = (data) => {
  return {
    type: INVENTORY_DATA_FORMAT_BY_ID_SUCCESS,
    data,
  };
};
export const getInventoryDataFormatFields = () => {
  return (dispatch) => {
    getInventoryDataFormatFieldsApi()
      .then((response) => {
        dispatch(getInventoryDataFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getInventoryDataFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=ChannelInventoryFormat',
    { headers: headers },
  );
  return result;
};
export const getInventoryDataFormatFieldsSuccess = (data) => {
  return {
    type: INVENTORY_DATA_FORMAT_FIELDS_SUCCESS,
    data,
  };
};
export const updateInventoryDataFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateInventoryDataFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push('/inventory-data/inventory-data-excel-upload');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const updateInventoryDataFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'upload-format/' + id + '?app-functionality=ChannelInventoryFormat',
    data,
    { headers: headers },
  );
  return result;
};
export const addInventoryDataFormat = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addInventoryDataFormatApi(data)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push('/inventory-data/inventory-data-excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};
const addInventoryDataFormatApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'upload-format?app-functionality=ChannelInventoryFormat',
    data,
    { headers: headers },
  );
  return result;
};
export const deleteInventoryDataFormat = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteInventoryDataFormatApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success('Successfully deleted');
          dispatch(getInventoryDataFormat());
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
const deleteInventoryDataFormatApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};
//upload File
export const uploadInventoryDataFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadInventoryDataFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadInventoryDataSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          dispatch(fetchEnd());
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadInventoryDataFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('channel-inventory-upload?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const uploadInventoryDataSuccess = (data) => {
  return {
    type: INVENTORY_DATA_UPLOAD_SUCCESS,
    data,
  };
};
//inventory data
export const getInventoryData = () => {
  return (dispatch) => {
    getInventoryDataApi().then((response) => {
      dispatch(getInventoryDataSuccess(response.data.response));
    });
  };
};

const getInventoryDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('channel-inventory?page=1&&limit=0', { headers: headers });
  return result;
};

export const getInventoryDataSuccess = (data) => {
  return {
    type: INVENTORY_DATA_SUCCESS,
    data,
  };
};

export const createInventoryData = (data, type, id) => {
  return (dispatch) => {
    createInventoryDataAPI(data, type, id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getInventoryData());

        history.push('/inventory-data');
      })
      .catch((error) => { });
  };
};
const createInventoryDataAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'delete') {
    result = axios.delete('channel-inventory/' + id, { headers: headers });
  } else if (type === 'edit') {
    result = axios.put('channel-inventory/' + id, data, { headers: headers });
  } else {
    result = axios.post('channel-inventory', data, { headers: headers });
  }
  return result;
};
export const getInventoryDataSingleItem = (id) => {
  return (dispatch) => {
    getInventoryDataSingleItemAPI(id).then((response) => {
      dispatch(getInventoryDataSingleItemSuccess(response.data.response));
    });
  };
};
const getInventoryDataSingleItemAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('channel-inventory/' + id, { headers: headers });
  return result;
};

export const getInventoryDataSingleItemSuccess = (data) => {
  return {
    type: INVENTORY_DATA_SINGLE_SUCCESS,
    data,
  };
};
// Demand supply format upload
export const getDemandSupplyFormat = () => {
  return (dispatch) => {
    getDemandSupplyFormatDataApi()
      .then((response) => {
        dispatch(getDemandSupplyFormatSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getDemandSupplyFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format?app-functionality=DemandSupplyDataFormat', {
    headers: headers,
  });
  return result;
};

export const getDemandSupplyFormatSuccess = (data) => {
  return {
    type: DEMAND_FORMAT_SUCCESS,
    data,
  };
};

export const getDemandSupplyFormatDataById = (id) => {
  return (dispatch) => {
    getDemandSupplyFormatByIdApi(id)
      .then((response) => {
        dispatch(getDemandSupplyFormatByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getDemandSupplyFormatByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format/' + id + '?app-functionality=DemandSupplyDataFormat',
    { headers: headers },
  );
  return result;
};

export const getDemandSupplyFormatByIdSuccess = (data) => {
  return {
    type: DEMAND_SUPPLY_FORMAT_BY_ID_SUCCESS,
    data,
  };
};
export const getDemandSupplyFormatFields = () => {
  return (dispatch) => {
    getDemandSupplyFormatFieldsApi()
      .then((response) => {
        dispatch(getDemandSupplyFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getDemandSupplyFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=DemandSupplyDataFormat',
    { headers: headers },
  );
  return result;
};
export const getDemandSupplyFormatFieldsSuccess = (data) => {
  return {
    type: DEMAND_SUPPLY_FORMAT_FIELDS_SUCCESS,
    data,
  };
};
export const updateDemandSupplyFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateDemandSupplyFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push('/demand-supply/demand-supply-excel-upload');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const updateDemandSupplyFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'upload-format/' + id + '?app-functionality=DemandSupplyDataFormat',
    data,
    { headers: headers },
  );
  return result;
};
export const addDemandSupplyFormat = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addDemandSupplyFormatApi(data)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push('/demand-supply/demand-supply-excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};
const addDemandSupplyFormatApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'upload-format?app-functionality=DemandSupplyDataFormat',
    data,
    { headers: headers },
  );
  return result;
};
export const deleteDemandSupplyFormat = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteDemandSupplyFormatApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success('Successfully deleted');
          dispatch(getDemandSupplyFormat());
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
const deleteDemandSupplyFormatApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};
//upload File
export const uploadDemandSupplyFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadDemandSupplyFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadDemandSupplySuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          dispatch(fetchEnd());
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadDemandSupplyFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('/upload-demand-supply-data?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const uploadDemandSupplySuccess = (data) => {
  return {
    type: DEMAND_SUPPLY_UPLOAD_SUCCESS,
    data,
  };
};
//Demand Supply
export const getDemandSupply = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getDemandSupplyApi()
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getDemandSupplySuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getDemandSupplyApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('demand-supply-data?page=1&&limit=0', {
    headers: headers,
  });
  return result;
};

export const getDemandSupplySuccess = (data) => {
  return {
    type: DEMAND_SUPPLY_SUCCESS,
    data,
  };
};

export const createDemandSupply = (data, type, id) => {
  return (dispatch) => {
    createDemandSupplyAPI(data, type, id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getDemandSupply());

        history.push('/demand-supply');
      })
      .catch((error) => { });
  };
};
const createDemandSupplyAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'delete') {
    result = axios.delete('demand-supply-data/' + id, { headers: headers });
  } else if (type === 'edit') {
    result = axios.put('demand-supply-data/' + id, data, { headers: headers });
  } else {
    result = axios.post('demand-supply-data', data, { headers: headers });
  }
  return result;
};
export const getDemandSupplySingleItem = (id) => {
  return (dispatch) => {
    getDemandSupplySingleItemAPI(id).then((response) => {
      dispatch(getDemandSupplySingleItemSuccess(response.data.response));
    });
  };
};
const getDemandSupplySingleItemAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('demand-supply-data/' + id, { headers: headers });
  return result;
};

export const getDemandSupplySingleItemSuccess = (data) => {
  return {
    type: DEMAND_SUPPLY_SINGLE_SUCCESS,
    data,
  };
};

// Competitor Data
export const getCompetitorDataFormatData = (type) => {
  return (dispatch) => {
    getCompetitorDataFormatDataAPI(type)
      .then((response) => {
        dispatch(getCompetitorDataFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getCompetitorDataFormatDataAPI = (type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'List Price') {
    result = axios.get(
      'upload-format?app-functionality=PricingCompetitorListPriceFormat',
      { headers: headers },
    );
  } else {
    result = axios.get(
      'upload-format?app-functionality=CompetitorSalesDataFormat',
      { headers: headers },
    );
  }
  return result;
};
export const getCompetitorDataFormatDataSuccess = (data) => {
  return {
    type: COMPETITOR_DATA_FORMAT_SUCCESS,
    data,
  };
};
export const getCompetitorDataFormatDataById = (id) => {
  return (dispatch) => {
    getCompetitorDataFormatDataByIdAPI(id)
      .then((response) => {
        dispatch(
          getCompetitorDataFormatDataByIdSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getCompetitorDataFormatDataByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format/' + id, { headers: headers });
  return result;
};
export const getCompetitorDataFormatDataByIdSuccess = (data) => {
  return {
    type: COMPETITOR_DATA_FORMAT_BY_ID_SUCCESS,
    data,
  };
};
//get membership format fields
export const getCompetitorDataFormatFields = (type) => {
  return (dispatch) => {
    getCompetitorDataFormatFieldsAPI(type)
      .then((response) => {
        dispatch(getCompetitorDataFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getCompetitorDataFormatFieldsAPI = (type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'List Price') {
    result = axios.get(
      'upload-format-fields?app-functionality=PricingCompetitorListPriceFormat',
      { headers: headers },
    );
  } else {
    result = axios.get(
      'upload-format-fields?app-functionality=CompetitorSalesDataFormat',
      { headers: headers },
    );
  }
  return result;
};
export const getCompetitorDataFormatFieldsSuccess = (data) => {
  return {
    type: COMPETITOR_DATA_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

// update format
export const updateCompetitorDataFormatData = (id, data, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateCompetitorDataFormatDataAPI(id, data, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          if (type === 'List Price') {
            history.push('/list-price/list-price-excel-upload');
          } else {
            history.push('/competitor-data/competitor-data-excel-upload');
          }
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
const updateCompetitorDataFormatDataAPI = (id, data, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'List Price') {
    result = axios.put(
      'upload-format/' +
      id +
      '?app-functionality=PricingCompetitorListPriceFormat',
      data,
      { headers: headers },
    );
  } else {
    result = axios.put(
      'upload-format/' + id + '?app-functionality=CompetitorSalesDataFormat',
      data,
      { headers: headers },
    );
  }
  return result;
};

//add format
export const addCompetitorDataFormatData = (data, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addCompetitorDataFormatDataAPI(data, type)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        if (type === 'List Price') {
          history.push('/list-price/list-price-excel-upload');
        } else {
          history.push('/competitor-data/competitor-data-excel-upload');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};
const addCompetitorDataFormatDataAPI = (data, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'List Price')
    result = axios.post(
      'upload-format?app-functionality=PricingCompetitorListPriceFormat',
      data,
      { headers: headers },
    );
  else {
    result = axios.post(
      'upload-format?app-functionality=CompetitorSalesDataFormat',
      data,
      { headers: headers },
    );
  }
  return result;
};
// delete format
export const deleteCompetitorDataFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteCompetitorDataFormatDataAPI(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success('Successfully deleted');
          dispatch(getCompetitorDataFormatData());
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
const deleteCompetitorDataFormatDataAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};
//upload File
export const uploadCompetitorDataFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadCompetitorDataFileAPI(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadCompetitorDataFileSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          dispatch(fetchEnd());
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadCompetitorDataFileAPI = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('upload-market-share?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const uploadCompetitorDataFileSuccess = (data) => {
  return {
    type: COMPETITOR_DATA_UPLOAD_SUCCESS,
    data,
  };
};
// add competitor list price
export const AddListPriceData = (data, type, id) => {
  return (dispatch) => {
    AddListPriceDataAPI(data, type, id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getDemandSupply());

        history.push('/list-price');
      })
      .catch((error) => { });
  };
};
const AddListPriceDataAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'delete') {
    result = axios.delete('market-price/' + id, { headers: headers });
  } else if (type === 'edit') {
    result = axios.put('market-price/' + id, data, { headers: headers });
  } else {
    result = axios.post('market-price', data, { headers: headers });
  }
  return result;
};
// Off Invoice Cost
export const offInvoiceCostErrorListSuccess = (data, errorList) => {
  return {
    type: OFF_INVOICE_COST_ERROR_LIST_SUCCESS,
    data,
    errorList,
  };
};

export const offInvoiceCostErrorListFailed = (data) => {
  return {
    type: OFF_INVOICE_COST_ERROR_LIST_FAILED,
    data,
  };
};
export const getOffInvoiceCostFormatData = () => {
  return (dispatch) => {
    getOffInvoiceCostFormatDataAPI()
      .then((response) => {
        dispatch(getOffInvoiceCostFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getOffInvoiceCostFormatDataAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=StagingPricingOffInvoiceCostFormat',
    { headers: headers },
  );
  return result;
};
export const getOffInvoiceCostFormatDataSuccess = (data) => {
  return {
    type: OFFINVOICE_COST_FORMAT_SUCCESS,
    data,
  };
};
export const getOffInvoiceCostFormatDataById = (id) => {
  return (dispatch) => {
    getOffInvoiceCostFormatDataByIdAPI(id)
      .then((response) => {
        dispatch(
          getOffInvoiceCostFormatDataByIdSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getOffInvoiceCostFormatDataByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format/' + id, { headers: headers });
  return result;
};
export const getOffInvoiceCostFormatDataByIdSuccess = (data) => {
  return {
    type: OFFINVOICE_COST_FORMAT_BY_ID_SUCCESS,
    data,
  };
};
//get membership format fields
export const getOffInvoiceCostFormatFields = () => {
  return (dispatch) => {
    getOffInvoiceCostFormatFieldsAPI()
      .then((response) => {
        dispatch(getOffInvoiceCostFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getOffInvoiceCostFormatFieldsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=StagingPricingOffInvoiceCostFormat',
    { headers: headers },
  );
  return result;
};
export const getOffInvoiceCostFormatFieldsSuccess = (data) => {
  return {
    type: OFFINVOICE_COST_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

// update format
export const updateOffInvoiceCostFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateOffInvoiceCostFormatDataAPI(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push('/off-Invoice-cost/off-Invoice-cost-excel-upload');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
const updateOffInvoiceCostFormatDataAPI = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'upload-format/' +
    id +
    '?app-functionality=StagingPricingOffInvoiceCostFormat',
    data,
    { headers: headers },
  );
  return result;
};

//add format
export const addOffInvoiceCostFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addOffInvoiceCostFormatDataAPI(data)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push('/off-Invoice-cost/off-Invoice-cost-excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};
const addOffInvoiceCostFormatDataAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'upload-format?app-functionality=StagingPricingOffInvoiceCostFormat',
    data,
    { headers: headers },
  );
  return result;
};
// delete format
export const deleteOffInvoiceCostFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteOffInvoiceCostFormatDataAPI(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success('Successfully deleted');
          dispatch(getOffInvoiceCostFormatData());
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
const deleteOffInvoiceCostFormatDataAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};
//upload File
export const uploadOffInvoiceCostFile = (id, file) => {
  return (dispatch) => {
    uploadOffInvoiceCostFileAPI(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadOffInvoiceCostFileSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          } else {
            //toast.error("Uploading Wrong File")
          }
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadOffInvoiceCostFileAPI = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('price-off-invoice-upload?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const uploadOffInvoiceCostFileSuccess = (data) => {
  return {
    type: OFFINVOICE_COST_UPLOAD_SUCCESS,
    data,
  };
};
export const runBuildOffInvoiceCostData = (data, type, item) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getBuildOffInvoiceCostDataAPi(data, type, item)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message)
      });
  };
};
const getBuildOffInvoiceCostDataAPi = (data, type, item) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;

  result = axios.post('build-price-off-invoice', data, { headers: headers });

  return result;
};

// Sales forecast
export const getSalesForecastData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getSalesForecastDataApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getSalesForecastDataSuccess(response.data.response));
        history.push('/sales-forecasting');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const getSalesForecastDataApi = (data) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'sales-forecast?page=1&&limit=0',
    { params: data },
    { headers: headers },
  );
  return result;
};

export const getSalesForecastDataSuccess = (data) => {
  return {
    type: SALES_FORECAST_DATA_SUCCESS,
    data,
  };
};
// sales forecast format
export const getSalesForecastFormatData = () => {
  return (dispatch) => {
    getSalesForecastFormatDataApi()
      .then((response) => {
        dispatch(getSalesForecastFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getSalesForecastFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=PromotionBudgetSalesForecastFormat',
    { headers: headers },
  );
  return result;
};

export const getSalesForecastFormatDataSuccess = (data) => {
  return {
    type: SALES_FORECAST_FORMAT_SUCCESS,
    data,
  };
};

export const getSalesForecastFormatDataById = (id) => {
  return (dispatch) => {
    getSalesForecastFormatDataByIdApi(id)
      .then((response) => {
        dispatch(getSalesForecastFormatDataByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getSalesForecastFormatDataByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format/' +
    id +
    '?app-functionality=PromotionBudgetSalesForecastFormat',
    { headers: headers },
  );
  return result;
};

export const getSalesForecastFormatDataByIdSuccess = (data) => {
  return {
    type: SALES_FORECAST_FORMAT_BY_ID_SUCCESS,
    data,
  };
};

//get sales forecast format fields
export const getSalesForecastFormatFields = () => {
  return (dispatch) => {
    getSalesForecastFormatFieldsApi()
      .then((response) => {
        dispatch(getSalesForecastFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getSalesForecastFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=PromotionBudgetSalesForecastFormat',
    { headers: headers },
  );
  return result;
};

export const getSalesForecastFormatFieldsSuccess = (data) => {
  return {
    type: SALES_FORECAST_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

// update sales forecast format
export const updateSalesForecastFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateSalesForecastFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push('/sales-forecasting/sales-forecasting-excel-upload');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const updateSalesForecastFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'upload-format/' +
    id +
    '?app-functionality=PromotionBudgetSalesForecastFormat',
    data,
    { headers: headers },
  );
  return result;
};

//add sales forecast format
export const addSalesForecastFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addSalesForecastFormatDataApi(data)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push('/sales-forecasting/sales-forecasting-excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addSalesForecastFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'upload-format?app-functionality=PromotionBudgetSalesForecastFormat',
    data,
    { headers: headers },
  );
  return result;
};

// delete sales forecast format
export const deleteSalesForecastFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteSalesForecastFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          dispatch(getSalesForecastFormatData());
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const deleteSalesForecastFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};

export const uploadSalesForecast = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadSalesForecastAPI(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadSalesForecastSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadSalesForecastAPI = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('sales-forecast-upload?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};

export const uploadSalesForecastSuccess = (data) => {
  return {
    type: SALES_FORECAST_SUCCESS,
    data,
  };
};
//  Coverage Cost
export const getCoverageCostData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCoverageCostDataApi()
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getCoverageCostDataSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getCoverageCostDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('coverage-cost?page=1&&limit=0', { headers: headers });
  return result;
};

export const getCoverageCostDataSuccess = (data) => {
  return {
    type: COVERAGE_COST_DATA_SUCCESS,
    data,
  };
};
// Coverage Cost format
export const getCoverageCostFormatData = () => {
  return (dispatch) => {
    getCoverageCostFormatDataApi()
      .then((response) => {
        dispatch(getCoverageCostFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getCoverageCostFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=PromotionCoverageCostsFormat',
    { headers: headers },
  );
  return result;
};

export const getCoverageCostFormatDataSuccess = (data) => {
  return {
    type: COVERAGE_COST_FORMAT_SUCCESS,
    data,
  };
};

export const getCoverageCostFormatDataById = (id) => {
  return (dispatch) => {
    getCoverageCostFormatDataByIdApi(id)
      .then((response) => {
        dispatch(getCoverageCostFormatDataByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getCoverageCostFormatDataByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format/' + id + '?app-functionality=PromotionCoverageCostsFormat',
    { headers: headers },
  );
  return result;
};

export const getCoverageCostFormatDataByIdSuccess = (data) => {
  return {
    type: COVERAGE_COST_FORMAT_BY_ID_SUCCESS,
    data,
  };
};

//get Coverage Cost format fields
export const getCoverageCostFormatFields = () => {
  return (dispatch) => {
    getCoverageCostFormatFieldsApi()
      .then((response) => {
        dispatch(getCoverageCostFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getCoverageCostFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=PromotionCoverageCostsFormat',
    { headers: headers },
  );
  return result;
};

export const getCoverageCostFormatFieldsSuccess = (data) => {
  return {
    type: COVERAGE_COST_FORMAT_FIELDS_SUCCESS,
    data,
  };
};
export const deleteCoverageCostData = (id) => {
  return (dispatch) => {
    deleteCoverageCostDataApi(id).then((response) => {
      toast.success(response.data.response);
      dispatch(getCoverageCostData());
    });
  };
};
const deleteCoverageCostDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('coverage-cost/' + id, { headers: headers });
  return result;
};
// update Coverage Cost format
export const updateCoverageCostFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateCoverageCostFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push('/coverage-cost/coverage-cost-excel-upload');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const updateCoverageCostFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'upload-format/' + id + '?app-functionality=PromotionCoverageCostsFormat',
    data,
    { headers: headers },
  );
  return result;
};

//add Coverage Costt format
export const addCoverageCostFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addCoverageCostFormatDataApi(data)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push('/coverage-cost/coverage-cost-excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addCoverageCostFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'upload-format?app-functionality=PromotionCoverageCostsFormat',
    data,
    { headers: headers },
  );
  return result;
};

// delete Coverage Cost format
export const deleteCoverageCostFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteCoverageCostFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          dispatch(getCoverageCostFormatData());
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const deleteCoverageCostFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};

export const uploadCoverageCost = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadCoverageCostAPI(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadCoverageCostSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
          if (response.data.response?.warning_msg)
            toast.warning('We have some warnings, please check Data log.')
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadCoverageCostAPI = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('upload-file?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};

export const uploadCoverageCostSuccess = (data, errorList) => {
  return {
    type: UPLOAD_COVERAGE_COST_SUCCESS,
    data,
  };
};

//competitor data
export const getCompetitorData = (type) => {
  return (dispatch) => {
    getCompetitorDataAPI(type)
      .then((response) => {
        dispatch(getCompetitorDataSuccess(response.data.response));
      })

      .catch((error) => {
        //toast.error('Error');
      });
  };
};

const getCompetitorDataAPI = (type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'List Price') {
    result = axios.get('market-price?page=1&&limit=0', { headers: headers });
  } else {
    result = axios.get('competitor-data?page=1&&limit=0', { headers: headers });
  }

  return result;
};

export const getCompetitorDataSuccess = (data) => {
  return {
    type: COMPETITOR_DATA,
    data,
  };
};
export const getCompetitorDataById = (id, type) => {
  return (dispatch) => {
    getCompetitorDataByIdAPI(id, type)
      .then((response) => {
        dispatch(getCompetitorDataByIdSuccess(response.data.response));
      })

      .catch((error) => {
        //toast.error('Error');
      });
  };
};

const getCompetitorDataByIdAPI = (id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'List Price') {
    result = axios.get('market-price/' + id, { headers: headers });
  } else {
    result = axios.get('competitor-data/' + id, { headers: headers });
  }

  return result;
};

export const getCompetitorDataByIdSuccess = (data) => {
  return {
    type: COMPETITOR_DATA_BY_ID,
    data,
  };
};
export const editCompetitorData = (data, id) => {
  return (dispatch) => {
    editCompetitorDataApi(data, id).then((response) => {
      history.push('/membership');
      toast.success(response.data.response);
    });
  };
};

const editCompetitorDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.put('competitor-data/' + id, data, { headers: headers });
  return result;
};

export const uploadEDI = (file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadEDIAPI(file)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success('File have been successfully updated.');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const uploadEDIAPI = (file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result = axios.post('upload-claim-response-edi', file, {
    headers: headers,
  });
  return result;
};
export const DeleteCompetitorData = (id, type) => {
  return (dispatch) => {
    DeleteCompetitorDataAPI(id, type)
      .then((response) => {
        toast.success(response.data.response);
        if (type === 'List Price') {
          history.push('/list-price');
          dispatch(getCompetitorData('List Price'));
        } else {
          history.push('/competitor-data');
          dispatch(getCompetitorData());
        }
      })
      .catch((error) => {
        //toast.error(error.response.data);
      });
  };
};
const DeleteCompetitorDataAPI = (id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'List Price') {
    result = axios.delete('market-price/' + id, { headers: headers });
  } else {
    result = axios.delete('competitor-data/' + id, { headers: headers });
  }
  return result;
};
//incoming claim edi upload
export const incomingClaimEDIUpload = (file, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    incomingClaimEDIUploadApi(file, id)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const incomingClaimEDIUploadApi = (file, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result = axios.post('incoming-claim-edi', file, { headers: headers });
  return result;
};
//master data dashborad
export const getMasterDataDashboard = (type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getMasterDataDashboardAPI(type)
      .then((response) => {
        if (type === 'Master Data') {
          dispatch(getMasterDataDashboardAPISuccess(response.data.response));
        } else {
          dispatch(
            getMasterDataDashboardTransactionAPISuccess(response.data.response),
          );
        }
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(getMasterDataDashboardAPISuccess(error.response.data));
        dispatch(
          getMasterDataDashboardTransactionAPISuccess(error.response.data),
        );
        dispatch(fetchEnd());
        toast.error(error.response.data.message);
      });
  };
};

const getMasterDataDashboardAPI = (type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'Transaction Data') {
    result = axios.get('transaction-data/count?app_source_type=' + type, {
      headers: headers,
    });
  } else if (type === 'Master Data') {
    result = axios.get('master-data/count?app_source_type=' + type, {
      headers: headers,
    });
  }
  return result;
};

export const getMasterDataDashboardAPISuccess = (data) => {
  return {
    type: MASTER_DATA_DASHBOARD_SUCCESS,
    data,
  };
};
export const getMasterDataDashboardTransactionAPISuccess = (data) => {
  return {
    type: MASTER_DATA_DASHBOARD_TRANSACTION_SUCCESS,
    data,
  };
};
// Master Data Dashboard API
export const getMasterDataDashboardChanges = (type) => {
  return (dispatch) => {
    getMasterDataDashboardChangesAPI(type).then((response) => {
      dispatch(getMasterDataDashboardChangesAPISuccess(response.data.response));
    });
  };
};

const getMasterDataDashboardChangesAPI = (type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'master-data/total-count-by-status?application_type=' + type,
    { headers: headers },
  );
  return result;
};

export const getMasterDataDashboardChangesAPISuccess = (data) => {
  return {
    type: MASTER_DATA_DASHBOARD_CHANGES_SUCCESS,
    data,
  };
};
// Master Data Operation
export const getMasterDataByOperation = (
  appFunctionality,
  Operation,
  type,
  data,
) => {
  return (dispatch) => {
    getMasterDataByOperationAPI(appFunctionality, Operation, type, data).then(
      (response) => {
        if (response.data.response.total_record > 0) {
          dispatch(fetchEnd());
          dispatch(getMasterDataByOperationAPISuccess(response.data.response));
          history.push('/master-data-details');
        } else {
          toast.warning('No records found.');
        }
      },
    );
  };
};

const getMasterDataByOperationAPI = (
  appFunctionality,
  Operation,
  type,
  data,
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'Search') {
    result = axios.get(
      'filter-master-data-changes?limit=0&app_functionality=' +
      appFunctionality +
      '&operation=' +
      Operation,
      { params: data },
      { headers: headers },
    );
  } else {
    result = axios.get(
      'master-data-operation-changes?limit=0&app_functionality=' +
      appFunctionality +
      '&operation=' +
      Operation,
      { headers: headers },
    );
  }
  return result;
};

export const getMasterDataByOperationAPISuccess = (data) => {
  return {
    type: MASTER_OPERATION_DATA_SUCCESS,
    data,
  };
};
// master data dashboard chart api
export const getMasterDataDashboardChart = (type) => {
  return (dispatch) => {
    getMasterDataDashboardChartAPI(type).then((response) => {
      dispatch(fetchEnd());
      dispatch(getMasterDataDashboardChartAPISuccess(response.data.response));
    });
  };
};

const getMasterDataDashboardChartAPI = (type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'master-data/period-count-by-status?interval=month' +
    '&application_type=' +
    type,
    { headers: headers },
  );
  return result;
};

export const getMasterDataDashboardChartAPISuccess = (data) => {
  return {
    type: MASTER_DATA_CHART_SUCCESS,
    data,
  };
};
//indirect sales data
export const getAllIndirectSalesDataList = (pagination, data, limit) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAllIndirectSalesDataListAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.message);
        dispatch(
          getAllIndirectSalesDataListSuccess(response.data.response, null),
        );
        if (sessionStorage.getItem('Syncfusion')) {
          history.push('/display-indirect-sales-invoice-data-sf');
        } else {
          history.push('/display-indirect-sales-invoice-data');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getAllIndirectSalesDataListAPI = (data) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  const result = axios.get(
    '/indirect-sales-data',
    { params: data },
    { headers: headers },
  );
  return result;
};
export const getAllIndirectSalesDataListSuccess = (data, errorList) => {
  return {
    type: ALL_INDIRECT_SALES_DATA_LIST_SUCCESS,
    data,
    errorList,
  };
};

//get All details of a particular sales data invoice by ID
export const getIndirectSalesDataDetailsById = (id) => {
  return (dispatch) => {
    getIndirectSalesDataDetailsByIdAPI(id)
      .then((response) => {
        dispatch(
          getIndirectSalesDataDetailsByIdSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};
export const getIndirectSalesDataDetailsByIdSuccess = (data) => {
  return {
    type: INDIRECT_SALES_DATA_DETAILS_SINGLE_SUCCESS,
    data,
  };
};
const getIndirectSalesDataDetailsByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('indirect-sales-data/' + id, { headers: headers });
  return result;
};

//Purchase sales data
export const getAllPurchaseSalesDataList = (pagination, data, limit) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAllPurchaseSalesDataListAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.message);
        dispatch(
          getAllPurchaseSalesDataListSuccess(response.data.response, null),
        );
        if (sessionStorage.getItem('Syncfusion')) {
          history.push('/display-purchase-sales-invoice-data-sf');
        } else {
          history.push('/display-purchase-sales-invoice-data');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getAllPurchaseSalesDataListAPI = (data) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  const result = axios.get(
    '/purchase-data',
    { params: data },
    { headers: headers },
  );
  return result;
};
export const getAllPurchaseSalesDataListSuccess = (data, errorList) => {
  return {
    type: ALL_PURCHASE_SALES_DATA_LIST_SUCCESS,
    data,
    errorList,
  };
};

//get All details of a particular sales data invoice by ID
export const getPurchaseSalesDataDetailsById = (id) => {
  return (dispatch) => {
    getPurchaseSalesDataDetailsByIdAPI(id)
      .then((response) => {
        dispatch(
          getPurchaseSalesDataDetailsByIdSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};
export const getPurchaseSalesDataDetailsByIdSuccess = (data) => {
  return {
    type: PURCHASE_SALES_DATA_DETAILS_SINGLE_SUCCESS,
    data,
  };
};
const getPurchaseSalesDataDetailsByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('purchase-data/' + id, { headers: headers });
  return result;
};
// Master Data Configuration
export const createMasterDataConfiguration = (data, type, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createMasterDataConfigurationAPI(data, type, id)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/master-data-config');
        dispatch(getMasterDataConfiguration());
        if (type == 'edit') {
          toast.success('Successfully Updated.');
        } else if (type == 'clear') {
          toast.success('Successfully Deleted.');
        } else if (type === 'getAll') {
          dispatch(getMasterDataConfiguration());
        } else {
          toast.success('Successfully Created.');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createMasterDataConfigurationAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'create')
    result = axios.post('master-data-config', data, { headers: headers });
  else if (type === 'getAll')
    result = axios.get('master-data-config', { headers: headers });
  else
    result = axios.put('master-data-config/' + id, data, { headers: headers });
  return result;
};

export const getMasterDataConfiguration = () => {
  return (dispatch) => {
    getMasterDataConfigurationAPI()
      .then((response) => {
        //dispatch(getKeyFigureableDataComplete(response.data.response.total_record));
        dispatch(getMasterDataConfigurationSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getMasterDataConfigurationAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('/master-data-config', { headers: headers });
  return result;
};

export const getMasterDataConfigurationSuccess = (data) => {
  return {
    type: MASTER_DATA_CONFIG_SUCCESS,
    data,
  };
};

export const getApplicationLogs = (type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getApplicationLogsAPI(type)
      .then((response) => {
        dispatch(fetchEnd());
        //dispatch(getKeyFigureableDataComplete(response.data.response.total_record));
        dispatch(getApplicationLogsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getApplicationLogsAPI = (type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'Deployment A') {
    result = axios.get('service-a/logs', { headers: headers });
  }
  if (type === 'Deployment B') {
    result = axios.get('service-b/logs', { headers: headers });
  }
  if (type === 'Deployment C') {
    result = axios.get('service-c/logs', { headers: headers });
  }
  return result;
};

export const getApplicationLogsSuccess = (data) => {
  return {
    type: APPLICATION_LOGS_SUCCESS,
    data,
  };
};

//
export const updatePostings = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updatePostingsAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response && response.data && response.data.responses.length > 0) {
          response.data.responses.forEach((item) => {
            toast.success(item.response.message);
          });
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const updatePostingsAPI = (data) => {
  const headers = { Authorization: secureLocalStorage.getItem('aTk') };
  var result = axios.post('contractheader-accrualspayments-update', data, {
    headers: headers,
  });
  return result;
};
export const generateMassData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    generateMassDataAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response && response.data && response.data.response) {
          toast.success(response.data.response);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        // toast.error(error.response.data.message);
      });
  };
};
const generateMassDataAPI = (data) => {
  const headers = { Authorization: secureLocalStorage.getItem('aTk') };
  var result = axios.post('/sales_data/bulk_insert_direct_sales_data', data, {
    headers: headers,
  });
  return result;
};

export const kafkaUpload = (file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    kafkaUploadAPI(file)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success('File have been successfully updated.');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
const kafkaUploadAPI = (file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result = axios.post('kafka-test', file, {
    headers: headers,
  });
  return result;
};

// get single data for Coverage cost

export const getCoverageCostSingleItem = (id) => {
  return (dispatch) => {
    getCoverageCostSingleItemAPI(id).then((response) => {
      dispatch(getCoverageCostSingleItemSuccess(response.data.response));
    });
  };
};
const getCoverageCostSingleItemAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('coverage-cost/' + id, { headers: headers });
  return result;
};

export const getCoverageCostSingleItemSuccess = (data) => {
  return {
    type: COVERAGE_COST_SINGLE_SUCCESS,
    data,
  };
};

//edit page  Coverage cost table data

export const updateCoverageCostData = (data, id) => {
  return (dispatch) => {
    updateCoverageCostDataApi(data, id).then((response) => {
      toast.success('Successfully updated.');
      history.push('/coverage-cost');
    });
  };
};

const updateCoverageCostDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('update-coverage-cost/' + id, data, { headers: headers });
  return result;
};
// drive batch job Type and Freq
export const getBatchJobTypeFreq = (app) => {
  return (dispatch) => {
    getBatchJobTypeFreqAPI(app).then((response) => {
      dispatch(getBatchJobTypeFreqSuccess(response.data.response));
    });
  };
};
const getBatchJobTypeFreqAPI = (app) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('batch-job-definition?app_type=' + app, {
    headers: headers,
  });
  return result;
};

export const getBatchJobTypeFreqSuccess = (data) => {
  return {
    type: BATCH_TYPE_FREQ_SUCCESS,
    data,
  };
};
