export const QUALIFIER_CONFIG_SUCCESS = 'QUALIFIER_CONFIG_SUCCESS';
export const QUALIFIER_CONFIG_BY_ID = 'QUALIFIER_CONFIG_BY_ID';
export const FETCH_START = 'FETCH_START';
export const FETCH_END = 'FETCH_END';
export const PRICE_LIST_FIELDS = 'PRICE_LIST_FIELDS';
export const EDI_MAPPINGS_SUCCESS = 'EDI_MAPPINGS_SUCCESS';
export const PRICE_TYPE_CONFIG_SUCCESS = 'PRICE_TYPE_CONFIG_SUCCESS';
export const PRICE_TYPE_CONFIG_BY_ID = 'PRICE_TYPE_CONFIG_BY_ID';
export const PRICE_LIST_MASTER_SUCCESS = 'PRICE_LIST_MASTER_SUCCESS';
export const PRICE_LIST_MASTER_BY_ID = 'PRICE_LIST_MASTER_BY_ID';
export const PRICE_QUALIFIER_NAME_SUCCESS = 'PRICE_QUALIFIER_NAME_SUCCESS';
export const PRICE_TYPE_NAME_SUCCESS = 'PRICE_TYPE_NAME_SUCCESS';
export const PRICE_LIST_DATA_BY_ID = 'PRICE_LIST_DATA_BY_ID';
export const PRICE_LIST_DATA_SUCCESS = 'PRICE_LIST_DATA_SUCCESS';
export const PRICE_MAINTENANCE_FIELDS = 'PRICE_MAINTENANCE_FIELDS';
export const DISTINCT_PRICE_LIST_DATA = 'DISTINCT_PRICE_LIST_DATA';
export const PRICE_FORMULA_BY_ID = 'PRICE_FORMULA_BY_ID';
export const PRICE_FORMULA_SUCCESS = 'PRICE_FORMULA_SUCCESS';
export const LIST_DATA_FORMAT_SUCCESS = 'LIST_DATA_FORMAT_SUCCESS';
export const OFF_INVOICE_CONFIG_SUCCESS = 'OFF_INVOICE_CONFIG_SUCCESS';
export const CONF_EDI_MAPPINGS_SUCCESS = 'CONF_EDI_MAPPINGS_SUCCESS';
export const PRICE_CAL_FOR_LIST_SUCCESS = 'PRICE_CAL_FOR_LIST_SUCCESS';
export const PRICE_CAL_FOR_LIST_BY_ID = 'PRICE_CAL_FOR_LIST_BY_ID';
export const PRICE_CAL_FOR_LIST_BY_NAME = 'PRICE_CAL_FOR_LIST_BY_NAME';
export const PRICE_CAL_FOR_SIMULATION_SUCCESS =
  'PRICE_CAL_FOR_SIMULATION_SUCCESS';
export const SIMULATION_DROPDOWN_SUCCESS = 'SIMULATION_DROPDOWN_SUCCESS';
export const PRICE_ELASTICITY = 'PRICE_ELASTICITY';
export const PRICE_SIM_RESULTS_SUCCESS = 'PRICE_SIM_RESULTS_SUCCESS';
export const LIST_DATA_UPLOAD_FAILURE = 'LIST_DATA_UPLOAD_FAILURE';
export const CUSTOMER_MARGIN_SUCCESS = 'CUSTOMER_MARGIN_SUCCESS';
export const PRICE_POLICY_EXCEP = 'PRICE_POLICY_EXCEP';
export const DISTINCT_PROFIT_LIST_DATA = 'DISTINCT_PROFIT_LIST_DATA';
export const COMPREHENCIVE_QUOTE_DATA = 'COMPREHENCIVE_QUOTE_DATA';
export const COMPREHENCIVE_QUOTE_DATA_BY_ID = 'COMPREHENCIVE_QUOTE_DATA_BY_ID';
export const PRODUCT_FEATURE_PRICING_SUCCESS =
  'PRODUCT_FEATURE_PRICING_SUCCESS';
export const PRODUCT_FEATURE_PRICING_SINGLE_SUCCESS =
  'PRODUCT_FEATURE_PRICING_SINGLE_SUCCESS';
export const PRODUCT_FEATURE_CALCULATE_SUCCESS =
  'PRODUCT_FEATURE_CALCULATE_SUCCESS';
export const PRODUCT_FEATURE_FORMAT_SUCCESS = 'PRODUCT_FEATURE_FORMAT_SUCCESS';
export const PRODUCT_FEATURE_PRICING_UPLOAD_FAILURE =
  'PRODUCT_FEATURE_PRICING_UPLOAD_FAILURE';
export const PRODUCT_FEATURE_FORMAT_FIELDS_SUCCESS =
  'PRODUCT_FEATURE_FORMAT_FIELDS_SUCCESS';
export const PRICE_MAINTENANCE_FIELDS_BY_PRICE_TYPE =
  'PRICE_MAINTENANCE_FIELDS_BY_PRICE_TYPE';
export const PRICE_LIST_DATA_MESSAGELOG = 'PRICE_LIST_DATA_MESSAGELOG';
export const PRICE_UNIQUE_SUCCESS = 'PRICE_UNIQUE_SUCCESS';
export const PRICE_FILTER_DATA = 'PRICE_FILTER_DATA';
export const CATALOG_FILTER_DATA = 'CATALOG_FILTER_DATA';
export const PRICE_CATALOG_SUCCESS = 'PRICE_CATALOG_SUCCESS';
export const PRICE_ADJUST_SUCCESS = 'PRICE_ADJUST_SUCCESS';
export const PRICE_REQUEST_CONFIG_BY_ID = 'PRICE_REQUEST_CONFIG_BY_ID';
export const PRICE_REQUEST_CONFIG_SUCCESS = 'PRICE_REQUEST_CONFIG_SUCCESS';
export const PRICE_REASON_CODE_CONFIG_SUCCESS = 'PRICE_REASON_CODE_CONFIG_SUCCESS';
export const PRICE_REASON_CODE_CONFIG_BY_ID = 'PRICE_REASON_CODE_CONFIG_BY_ID';
export const PRICE_CONTRACT_ID = "PRICE_CONTRACT_ID"
export const PRICE_CONTRACTS_LIST = "PRICE_CONTRACTS_LIST"
export const PRICE_REQUESTS_LIST_SUCCESS = 'PRICE_REQUESTS_LIST_SUCCESS';
export const PRICE_REQUESTS_LIST_ID_SUCCESS = 'PRICE_REQUESTS_LIST_ID_SUCCESS';
export const PRICE_REQUESTS_SUCCESS = 'PRICE_REQUESTS_SUCCESS';
export const PRICE_REQUESTS_BY_ID_SUCCESS = 'PRICE_REQUESTS_BY_ID_SUCCESS';
export const RESET_PRICE_LIST_DATA = 'RESET_PRICE_LIST_DATA';
export const RESET_PRICE_MAINTENANCE_FIELDS = 'RESET_PRICE_MAINTENANCE_FIELDS';
export const PRICE_CONTRACTS = 'PRICE_CONTRACTS';
export const PRICE_CHANGE_HISTORY_DATA_SUCCESS = 'PRICE_CHANGE_HISTORY_DATA_SUCCESS';
