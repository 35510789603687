import {
  QUALIFIER_CONFIG_SUCCESS,
  QUALIFIER_CONFIG_BY_ID,
  FETCH_START,
  FETCH_END,
  PRICE_LIST_FIELDS,
  EDI_MAPPINGS_SUCCESS,
  PRICE_TYPE_CONFIG_SUCCESS,
  PRICE_TYPE_CONFIG_BY_ID,
  PRICE_QUALIFIER_NAME_SUCCESS,
  PRICE_TYPE_NAME_SUCCESS,
  PRICE_LIST_MASTER_SUCCESS,
  PRICE_LIST_MASTER_BY_ID,
  PRICE_LIST_DATA_BY_ID,
  PRICE_LIST_DATA_SUCCESS,
  RESET_PRICE_LIST_DATA,
  PRICE_MAINTENANCE_FIELDS,
  DISTINCT_PRICE_LIST_DATA,
  PRICE_FORMULA_BY_ID,
  PRICE_FORMULA_SUCCESS,
  LIST_DATA_FORMAT_SUCCESS,
  OFF_INVOICE_CONFIG_SUCCESS,
  CONF_EDI_MAPPINGS_SUCCESS,
  PRICE_CAL_FOR_LIST_SUCCESS,
  PRICE_CAL_FOR_LIST_BY_ID,
  PRICE_CAL_FOR_LIST_BY_NAME,
  PRICE_CAL_FOR_SIMULATION_SUCCESS,
  PRICE_CHANGE_HISTORY_DATA_SUCCESS,
  SIMULATION_DROPDOWN_SUCCESS,
  PRICE_ELASTICITY,
  PRICE_SIM_RESULTS_SUCCESS,
  LIST_DATA_UPLOAD_FAILURE,
  CUSTOMER_MARGIN_SUCCESS,
  PRICE_POLICY_EXCEP,
  DISTINCT_PROFIT_LIST_DATA,
  COMPREHENCIVE_QUOTE_DATA,
  COMPREHENCIVE_QUOTE_DATA_BY_ID,
  PRODUCT_FEATURE_PRICING_SUCCESS,
  PRODUCT_FEATURE_PRICING_SINGLE_SUCCESS,
  PRODUCT_FEATURE_CALCULATE_SUCCESS,
  PRODUCT_FEATURE_FORMAT_SUCCESS,
  PRODUCT_FEATURE_PRICING_UPLOAD_FAILURE,
  PRODUCT_FEATURE_FORMAT_FIELDS_SUCCESS,
  PRICE_MAINTENANCE_FIELDS_BY_PRICE_TYPE,
  PRICE_LIST_DATA_MESSAGELOG,
  PRICE_UNIQUE_SUCCESS,
  PRICE_FILTER_DATA,
  CATALOG_FILTER_DATA,
  PRICE_CATALOG_SUCCESS,
  PRICE_ADJUST_SUCCESS,
  PRICE_REQUEST_CONFIG_BY_ID,
  PRICE_REQUEST_CONFIG_SUCCESS,
  PRICE_REASON_CODE_CONFIG_SUCCESS,
  PRICE_REASON_CODE_CONFIG_BY_ID,
  PRICE_CONTRACTS_LIST,
  PRICE_CONTRACT_ID,
  PRICE_CONTRACTS,
  PRICE_REQUESTS_SUCCESS,
  PRICE_REQUESTS_BY_ID_SUCCESS,
  RESET_PRICE_MAINTENANCE_FIELDS
} from '../../actions/Pricing/ActionTypes';

const initialState = {
  loading: false,
  qualifierConfigData: [],
  qualifierConfigSingleData: [],
  ListFields: [],
  edimappingData: [],
  priceTypeData: [],
  priceTypeSingleData: [],
  priceQualifierNameList: [],
  priceTypeNameList: [],
  priceListMasterData: [],
  priceListMasterSingleData: [],
  priceListData: [],
  priceListSingleData: [],
  priceMaintenanceFields: [],
  distinctPriceList: [],
  priceFormulaList: [],
  priceFormulaSingle: [],
  listDataUploadFormat: [],
  offInvoiceConfigData: [],
  configEdiMappingsData: [],
  priceCalForListData: [],
  priceListCalForSingleData: [],
  priceListCalForDataByName: [],
  priceCalForSimulationData: [],
  priceChangeHistoryData: [],
  simulationNumberDropdown: [],
  priceElasticityData: [],
  priceCalForErrorList: [],
  priceSimResultData: [],
  listDataUploadError: [],
  customerMarginData: [],
  customerMarginErrorList: [],
  pricePolicyExcepData: [],
  distinctProfitList: [],
  comprehensiveQuoteData: [],
  comprehensiveQuoteSingleData: [],
  productFeaturePricingData: [],
  productFeaturePricingSingleData: [],
  productFeatureCalculateData: [],
  productFeaturePricingAllFormat: [],
  productFeaturePricingFormatError: [],
  productFeatureFormatFields: [],
  pricingFields: [],
  priceListMessageLog: [],
  uniquePriceData: [],
  priceFilterData: {},
  catalogFilterData: {},
  catalogReport: {},
  priceAdjustSimulationReport: {},
  priceRequestListData: [],
  priceRequestSingleData: [],
  priceReasonCodeListData: [],
  priceReasonCodeSingleData: [],
  priceContractList: [],
  priceContractId: {},
  priceRequests: [],
  priceRequestDetail: [],
  priceContracts: []
};

const PricingReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PRICE_MAINTENANCE_FIELDS:

      return {
        ...state,
        priceMaintenanceFields: [],
        priceTypeNameList: [],
        priceListMasterData: [],
        priceTypeData: [],
        priceRequestListData: [],
        pricingFields: [],
        priceListData: []

      };
    case QUALIFIER_CONFIG_SUCCESS:
      return {
        ...state,
        qualifierConfigData: action.data,
        loading: false,
      };
    case QUALIFIER_CONFIG_BY_ID:
      return {
        ...state,
        qualifierConfigSingleData: action.data,
        loading: false,
      };

    case FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_END:
      return {
        ...state,
        loading: false,
      };
    case PRICE_LIST_FIELDS:
      return {
        ...state,
        ListFields: action.data,
        loading: false,
      };
    case PRICE_TYPE_CONFIG_SUCCESS:
      return {
        ...state,
        priceTypeData: action.data,
        loading: false,
      };
    case EDI_MAPPINGS_SUCCESS:
      return {
        ...state,
        edimappingData: action.data,
        loading: false,
      };
    case PRICE_TYPE_CONFIG_BY_ID:
      return {
        ...state,
        priceTypeSingleData: action.data,
        loading: false,
      };
    case PRICE_QUALIFIER_NAME_SUCCESS:
      return {
        ...state,
        priceQualifierNameList: action.data,
        loading: false,
      };
    case PRICE_TYPE_NAME_SUCCESS:
      return {
        ...state,
        priceTypeNameList: action.data,
        loading: false,
      };
    case PRICE_LIST_MASTER_SUCCESS:
      return {
        ...state,
        priceListMasterData: action.data,
        loading: false,
      };
    case PRICE_LIST_MASTER_BY_ID:
      return {
        ...state,
        priceListMasterSingleData: action.data,
        loading: false,
      };
    case PRICE_LIST_DATA_BY_ID:
      return {
        ...state,
        priceListSingleData: action.data,
        loading: false,
      };
    case PRICE_LIST_DATA_SUCCESS:
      return {
        ...state,
        priceListData: action.data,
        loading: false,
      };
    case PRICE_MAINTENANCE_FIELDS:
      return {
        ...state,
        priceMaintenanceFields: action.data,
        loading: false,
      };
    case DISTINCT_PRICE_LIST_DATA:
      return {
        ...state,
        distinctPriceList: action.data,
        loading: false,
      };
    case PRICE_FORMULA_BY_ID:
      return {
        ...state,
        priceFormulaSingle: action.data,
        loading: false,
      };
    case PRICE_FORMULA_SUCCESS:
      return {
        ...state,
        priceFormulaList: action.data,
        loading: false,
      };
    case LIST_DATA_FORMAT_SUCCESS:
      return {
        ...state,
        listDataUploadFormat: action.data,
        loading: false,
      };
    case OFF_INVOICE_CONFIG_SUCCESS:
      return {
        ...state,
        offInvoiceConfigData: action.data,
        loading: false,
      };
    case CONF_EDI_MAPPINGS_SUCCESS:
      return {
        ...state,
        configEdiMappingsData: action.data,
        loading: false,
      };
    case PRICE_CAL_FOR_LIST_SUCCESS:
      return {
        ...state,
        priceCalForListData: action.data,
        loading: false,
      };
    case PRICE_CAL_FOR_LIST_BY_ID:
      return {
        ...state,
        priceListCalForSingleData: action.data,
        loading: false,
      };
    case PRICE_CAL_FOR_LIST_BY_NAME:
      return {
        ...state,
        priceListCalForDataByName: action.data,
        loading: false,
      };
    case PRICE_CAL_FOR_SIMULATION_SUCCESS:
      return {
        ...state,
        priceCalForSimulationData: action.data,
        priceCalForErrorList: action.errorList,
      };
    case PRICE_CHANGE_HISTORY_DATA_SUCCESS:
      return {
        ...state,
        priceChangeHistoryData: action.data,
        loading: false,
      };
    case SIMULATION_DROPDOWN_SUCCESS:
      return {
        ...state,
        simulationNumberDropdown: action.data,
      };
    case PRICE_ELASTICITY:
      return {
        ...state,
        priceElasticityData: action.data,
      };
    case PRICE_SIM_RESULTS_SUCCESS:
      return {
        ...state,
        priceSimResultData: action.data,
        loading: false,
      };
    case RESET_PRICE_LIST_DATA:
      return {
        ...state,   // Keep other parts of the state unchanged
        priceListMasterData: [],  // Reset price list data
        priceMaintenanceFields: [] // Reset maintenance fields
      };
    case LIST_DATA_UPLOAD_FAILURE:
      return {
        ...state,
        listDataUploadError: action.data,
        loading: false,
      };
    case CUSTOMER_MARGIN_SUCCESS:
      return {
        ...state,
        customerMarginData: action.data,
        customerMarginErrorList: action.errorList,
      };
    case PRICE_POLICY_EXCEP:
      return {
        ...state,
        pricePolicyExcepData: action.data,
        loading: false,
      };
    case DISTINCT_PROFIT_LIST_DATA:
      return {
        ...state,
        distinctProfitList: action.data,
        loading: false,
      };
    case COMPREHENCIVE_QUOTE_DATA:
      return {
        ...state,
        comprehensiveQuoteData: action.data,
        loading: false,
      };
    case COMPREHENCIVE_QUOTE_DATA_BY_ID:
      return {
        ...state,
        comprehensiveQuoteSingleData: action.data,
        loading: false,
      };
    case PRODUCT_FEATURE_PRICING_SUCCESS:
      return {
        ...state,
        productFeaturePricingData: action.data,
        loading: false,
      };
    case PRODUCT_FEATURE_PRICING_SINGLE_SUCCESS:
      return {
        ...state,
        productFeaturePricingSingleData: action.data,
        loading: false,
      };
    case PRODUCT_FEATURE_CALCULATE_SUCCESS:
      return {
        ...state,
        productFeatureCalculateData: action.data,
        loading: false,
      };
    case PRODUCT_FEATURE_FORMAT_SUCCESS:
      return {
        ...state,
        productFeaturePricingAllFormat: action.data,
        loading: false,
      };
    case PRODUCT_FEATURE_PRICING_UPLOAD_FAILURE:
      return {
        ...state,
        productFeaturePricingFormatError: action.data,
        loading: false,
      };
    case PRODUCT_FEATURE_FORMAT_FIELDS_SUCCESS:
      return {
        ...state,
        productFeatureFormatFields: action.data,
        loading: false,
      };
    case PRICE_MAINTENANCE_FIELDS_BY_PRICE_TYPE:
      return {
        ...state,
        pricingFields: action.data,
        loading: false,
      };
    case PRICE_LIST_DATA_MESSAGELOG:
      return {
        ...state,
        priceListMessageLog: action.data,
      };
    case PRICE_UNIQUE_SUCCESS:
      return {
        ...state,
        uniquePriceData: action.data,
      };
    case PRICE_FILTER_DATA:
      return {
        ...state,
        priceFilterData: action.data,
      };
    case CATALOG_FILTER_DATA:
      return {
        ...state,
        catalogFilterData: action.data,
      };
    case PRICE_CATALOG_SUCCESS:
      return {
        ...state,
        catalogReport: action.data,
      };
    case PRICE_ADJUST_SUCCESS:
      return {
        ...state,
        priceAdjustSimulationReport: action.data,
      };
    case PRICE_REQUEST_CONFIG_BY_ID:
      return {
        ...state,
        priceRequestSingleData: action.data,
        loading: false,
      };
    case PRICE_REQUEST_CONFIG_SUCCESS:
      return {
        ...state,
        priceRequestListData: action.data,
        loading: false,
      };
    case PRICE_REASON_CODE_CONFIG_BY_ID:
      return {
        ...state,
        priceReasonCodeSingleData: action.data,
        loading: false,
      };
    case PRICE_REASON_CODE_CONFIG_SUCCESS:
      return {
        ...state,
        priceReasonCodeListData: action.data,
        loading: false,
      };
    case PRICE_CONTRACTS_LIST:
      return {
        ...state,
        priceContractList: action.data,
        loading: false,
      };
    case PRICE_CONTRACT_ID:
      return {
        ...state,
        priceContractId: action.data,
        loading: false,
      };
    case PRICE_CONTRACTS:
      return {
        ...state,
        priceContracts: action.data,
        loading: false,
      };
    case PRICE_REQUESTS_SUCCESS:
      return {
        ...state,
        priceRequests: action.data,
        loading: false,
      };
    case PRICE_REQUESTS_BY_ID_SUCCESS:
      console.log('tests', action.data)
      return {
        ...state,
        priceRequestDetail: action.data,
        loading: false,
      };


    default:
      return state;
  }
};

export default PricingReducer;
